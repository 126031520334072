import axios from "axios";
//import { push } from "connected-react-router";
import setAuthToken from "../utils/setAuthToken";
import { API_URI } from "../utils/config";
//import jwt_decode from "jwt-decode";

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  CLEAR_CURRENT_USER,
  GET_USERS,
  GET_USER,
  GET_STATS,
  GET_RESTAURANT_VERIFICATION,
  RESET_RESTAURANT_PASSWORD,
} from "./types";

// Login - Get User Token
export const loginUser = (userData, history) => (dispatch) => {
  axios
    .post(`${API_URI}/users/login`, userData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error.message,
        });
      } else {
        // Save to localStorage
        const { token, data, user } = res.data;
        // Set token to ls
        localStorage.setItem("payClean", token);

        if (data.role_id === 4) {
          localStorage.setItem("restaurant_id", data.restaurant.id);

          setAuthToken(token);

          // Set current user
          dispatch(setCurrentUser(data));

          history.push("/orders");
        } else if (data.role_id === 5) {
          localStorage.setItem("id", data.id);

          localStorage.setItem("restaurant_id", data.restaurant_id);

          setAuthToken(token);

          // Set current user
          dispatch(setCurrentUser(data));

          history.push("/orders");
        } else if (data.role_id === 1) {
          // Set token to Auth header
          setAuthToken(token);
          // Set current user
          dispatch(setCurrentUser(data));

          history.push("/dashboard");
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      });
    });
};

//  Get User User
export const currentUser = () => (dispatch) => {
  axios
    .get(`${API_URI}/me/users`)
    .then((res) => {
      if (res.data.error.code) {
        // Remove token from localStorage
        localStorage.removeItem("payClean");
        localStorage.removeItem("restaurant_id");
        // Remove auth header for future requests
        setAuthToken(false);
        // Set current user
        dispatch(clearCurrentProfile());
        //history.push("/login");

        window.location.href = `${window.location.origin.toString()}/`;
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        // Save to localStorage
        const { data } = res.data;
        // Set current user
        dispatch(setCurrentUser(data));
      }
    })
    .catch((err) => {
      // Remove token from localStorage
      localStorage.removeItem("payClean");
      localStorage.removeItem("restaurant_id");
      // Remove auth header for future requests
      setAuthToken(false);
      // Set current user
      dispatch(clearCurrentProfile());
      //history.push("/login");

      window.location.href = `${window.location.origin.toString()}/`;
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      });
    });
};

export const clearCurrentProfile = () => (dispatch) => {
  return {
    type: CLEAR_CURRENT_USER,
    payload: {},
  };
};

// Set logged in user
export const setCurrentUser = (user) => {
  return {
    type: SET_CURRENT_USER,
    payload: user,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  axios
    .get(`${API_URI}/users/logout`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        // Remove token from localStorage
        localStorage.removeItem("payClean");

        localStorage.removeItem("restaurant_id");
        localStorage.clear();
        // Remove auth header for future requests
        setAuthToken(false);
        // Set current user
        dispatch(clearCurrentProfile());
        //history.push("/login");

        window.location.href = `${window.location.origin.toString()}/payclean_testing`;
        //window.location.href = "http://aqar.tecnovaters.com/";
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      });
    });
};

// Get all Users
export const getAllUser = () => (dispatch) => {
  axios
    .get(`${API_URI}/users?filter={"where": {"role_id": 3}}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_USERS,
          payload: data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      });
    });
};

// Single get user
export const getUser = (id) => (dispatch) => {
  axios
    .get(
      `${API_URI}/users/${id}` // ?filter={"include":{"0": "user", "1": "property_type", "2": "currency_type", "3": "city", "4": "state", "5": "country", "6": "area_type", "7": "attachment"},"where": {"is_active	": 1},"skip": "0","limit":"all"}`
    )
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_USER,
          payload: data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      });
    });
};

// Update User
export const updateUser = (userData, id, history) => (dispatch, getState) => {
  let users = [...getState().auth.users];
  let user = { ...getState().auth.user };
  axios
    .put(`${API_URI}/users/${id}`, userData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error.message,
        });
      } else {
        const { data } = res.data;

        users = users.map((user) => {
          if (user.id === data.id) {
            user = { ...user, ...data };
          }
          return user;
        });
        if (user.id === id) {
          dispatch(setCurrentUser(data));
        }
        dispatch({
          type: GET_USERS,
          payload: users,
        });
        // history.push("/user");
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      });
    });
};

export const getStats = () => (dispatch) => {
  axios
    .get(`${API_URI}/users/stats`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res;

        dispatch({
          type: GET_STATS,
          payload: data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      });
    });
};

// Get Password Verification
export const getPasswordVerification = (token, id) => (dispatch) => {
  axios
    .get(`${API_URI}/password/${token}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_RESTAURANT_VERIFICATION,
          payload: data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      });
    });
};

// Delete customer
export const deleteUser = (id) => (dispatch, getState) => {
  let users = [...getState().auth.users];

  axios
    .delete(`${API_URI}/users/${id}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        users = users.filter((gym) => gym.id !== id);
        dispatch({
          type: GET_USERS,
          payload: users,
        });
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      })
    );
};
/* 
export const clearData = () => (dispatch) => {
  dispatch({
    type: CLEAR_DATA,
  });
}; */

// Forgot Password
export const forgotPassword = (userData, history) => (dispatch) => {
  axios
    .post(`${API_URI}/users/forgot_password`, userData)
    .then((res) => {
      if (res.data.error.code === 1) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error.message,
        });
      } else {
        history.push("/forgotpasswordotpverification");
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      });
    });
};

//forgot password Otp Verification
export const forgotpasswordotpverification = (userData, history) => (
  dispatch,
  getState
) => {
  let forgotpasswordverification = [
    ...getState().auth.forgotpasswordverification,
  ];

  axios
    .post(`${API_URI}/users/verify_otp`, userData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        history.push("/resetpassword");
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      });
    });
};

// REstaurant  Reset Password
export const resetPassword = (userData) => (dispatch, getState) => {
  let reset = [...getState().auth.reset];

  axios
    .post(`${API_URI}/users/reset_password`, userData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        reset = reset.map((user) => {
          if (user.email === data.email) {
            user = { ...user, ...data };
          }

          return user;
        });
        dispatch({
          type: RESET_RESTAURANT_PASSWORD,
          payload: reset,
        });
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      })
    );
};

// Update admin password
export const changePassword = (userData, id) => (dispatch, getState) => {
  axios
    .post(`${API_URI}/users/change_password`, userData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        // Remove token from localStorage
        localStorage.removeItem("jwtToken");
        // Remove auth header for future requests
        setAuthToken(false);
        // Set current user
        dispatch(clearCurrentProfile());
        window.location.href = "/";
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      });
    });
};
