import React, { Component } from "react";
import isEmpty from "../../../validation/is-empty"

export class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: ""
    };
  }

  handleChange = event => {
   
   this.setState({ searchInput: event.target.value }, () =>
      this.globalSearch()
    );
  
  };


  globalSearch = () => {
    let { searchInput } = this.state;
    let { fields, data } = this.props;
    //console.log(searchInput, fields, data);
    let filteredData = [];
    fields.map(field => {
      let fieldData = data.filter(
        value =>
          value[field] &&
          value[field].toString().toLowerCase().includes(searchInput.toString().toLowerCase())
      );
      filteredData = [...filteredData, ...fieldData];
      return field;
    });

    if (!isEmpty(this.props.relationFields)) {
      let relationFields = this.props.relationFields;

      for (const relation in relationFields) {
        relationFields[relation].map(field => {
          let fieldData = data.filter(
            value =>
              value[relation] &&
              value[relation][field] &&
              value[relation][field].toString()
                .toLowerCase()
                .includes(searchInput.toString().toLowerCase())
          );
          filteredData = [...filteredData, ...fieldData];
          return field;
        });
      }
    }

    let uniq = {};
    filteredData = filteredData.filter(
      obj => !uniq[obj.id] && (uniq[obj.id] = true)
    );

    this.props.handleSetData(filteredData, searchInput);
  };

  render() {
    return (
      <input
        size="large"
        name="searchInput"
        value={this.state.searchInput || ""}
        onChange={this.handleChange}
        label="Search"
        placeholder="Search"
      />
    );
  }
}

export default Search;
