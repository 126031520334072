import {
    GET_ALL_RESTAURANTS,
    GET_SINGLE_RESTAURANT,
    CLEAR_DATA,
    GET_STATS_RES,
    GET_RESTAURANT
  } from "../actions/types";
  
  const initialState = {
    
    restaurants:[],
    restaurant: {},
    rescount: [],
    editrestaurant : {}
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case GET_ALL_RESTAURANTS:
        return {
          ...state,
         
         restaurants: action.payload
        };
        case GET_SINGLE_RESTAURANT:
            return {
              ...state,
             
             restaurant: action.payload
            };
            case GET_RESTAURANT:
              return {
                ...state,
               
               editrestaurant: action.payload
              };
            case GET_STATS_RES:
              return {
                ...state,
               
               rescount: action.payload
              };
            case CLEAR_DATA:
            return {
              ...state,
             
             restaurant: {}
            };
  
    
      
          
      default:
        return state;
    }
  }
  