import {
  SET_CURRENT_USER,
  CLEAR_CURRENT_USER,
  GET_USERS,
  GET_USER,
  GET_STATS,
  GET_RESTAURANT_VERIFICATION,
  FORGOT_PASSWORD_OTP_VERIFICATION,
  RESET_RESTAURANT_PASSWORD,
} from "../actions/types";

const initialState = {
  isAuthenticated: false,
  user: {},
  users: [],
  user: {},
  userstats: [],
  res_verification: {},
  forgotpasswordverification: [],
  reset: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };

    case CLEAR_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: false,
        user: action.payload,
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case GET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case GET_STATS:
      return {
        ...state,
        userstats: action.payload,
      };
    case GET_RESTAURANT_VERIFICATION:
      return {
        ...state,
        res_verification: action.payload,
      };
    case FORGOT_PASSWORD_OTP_VERIFICATION:
      return {
        ...state,
        forgotpasswordverification: action.payload,
      };
    case RESET_RESTAURANT_PASSWORD:
      return {
        ...state,
        reset: action.payload,
      };

    default:
      return state;
  }
}
