import React, { Component, Fragment } from "react";
import Menu from "../headerMenu";
import Modal from "../common/Modal/index";
import {
  getorder,
  updateOrder,
  getAllAcceptedOrders,
  getAllOrders,
} from "../../actions/orderAction";
import {

  updateIrdertem,

} from "../../actions/orderItems";
import {
  deleteItemOrderEdit,
  getAllOrdersItems,
} from "../../actions/orderItems";
import Agerestrictionpopup from "./ageRestrictedPop";
import Moment from "react-moment";
import { getAllOrdersCount } from "../../actions/orderAction";
import { getAllTables } from "../../actions/tableAction";
import { getAllOrdersStatus } from "../../actions/orderStatusAction";
import { connect } from "react-redux";
import Table from "../common/Table";
import { IMAGE_URI } from "../../utils/config";
//import "../../../node_modules/react-table/react-table.css"
import Search from "../common/Search/index";
import Body from "../Body/index";
import Sidemenu from "../Sidemenu";
import { Link } from "react-router-dom";
import Orderitemdelete from "./orderItemDelete";
import isEmpty from "../../validation/is-empty";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Spinner from "../common/Spinner/index";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var QRCode = require("qrcode.react");

class OrderpopUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchInput: "",
      filteredData: [],
      isShowDeleteUser: false,
      selectUser: {},
      selectorder: {},
      order_status: "",
      id: "",
      order_status_id: "",
      orderid: 0,
      starttime: 0,
      setSeconds: [],
      timerago: "",
      loaddata: false,
      acceptedorder_id: 0,
      ageRestriction: false,
      statusupdateid: false,
      is_shown: false,
      orderstatusid: "",
      receipt: false,
      receiptkitchen: true,
      orderidupdate : ""
    };
  }

  componentDidMount() {
  /*  this.props.getAllOrders();  */
    this.props.getAllOrdersStatus();
    this.props.getAllOrdersItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    /*  if(nextProps.orders){ */

    this.setState({
      orderid:
        !isEmpty(nextProps.orders) && !isEmpty(nextProps.orders[0])
          ? nextProps.orders[0].id
          : "",
      //timerago : !isEmpty(nextProps.orders) && !isEmpty(nextProps.orders[0].order_starting_time) ? nextProps.orders[0].order_starting_time : ""
    });

    /*   } */
  }

  handleCancelPopUp = () => {
    this.props.handleCancelPopUp();

  };

  handleCancelViewUser = () => {
    this.setState({ isShowViewUser: false, selectUser: {} });
  };

  handleViewClick = (id) => {
    const { restaurants } = this.props.res;
    let selectUser = restaurants.find((user) => user.id === id);
    this.setState({ isShowViewUser: true, selectUser });
  };

  handleCancelEditUser = () => {
    this.setState({ isShowEditUser: false, selectUser: {} });
  };

  handleUpdateClick = (id) => {
    const { isclick } = this.state;
    if (id === 2) {
      toast.success("Your Order is Accepted !", {
        position: "top-center",
        closeOnClick: true,
      });
    } else if (id === 3) {
      toast.error("Your Order is Cancelled !", {
        position: "top-center",
      });
    } else if (id === 4) {
      toast.success("Your Order is Completed !", {
        position: "top-center",
      });
    }

    this.props.updateOrder(
      { order_status_id: id },
      this.props.ord.order.id,
      this.props.history
    );
    this.handleCancelPopUp();
    if (id === 4) {
      this.props.getAllTables();
    }
  };

  handleActiveClick = (id) => {
    const { order } = this.props.ord;
    let selectUser = order.order_items.find((gym) => gym.id === id);
    let order_item_status = selectUser.order_item_status ? false : true;

    this.props.updateIrdertem({ order_item_status }, id, this.props.history);
   
   

  };

  handleCancelDeleteUser = () => {
    this.setState({ isShowDeleteUser: false, selectUser: {} });
  };


  printDiv = (divName) => {
    var generator = window.open(divName, "PRINT", "height=400,width=600");
    var printContents = document.getElementById(divName);
    generator.document.write(printContents.innerHTML.replace("Print Customer"));
    generator.document.close();
    generator.focus(); // necessary for IE >= 10*/
    generator.print();
    generator.close();
  };
  printDivkitt = (divName) => {
    console.log(divName);
    var generator = window.open(divName, "PRINT", "height=400,width=600");
    var printContents = document.getElementById(divName);
    generator.document.write(printContents.innerHTML.replace("Print Me"));
    generator.document.close();
    generator.focus(); // necessary for IE >= 10*/
    generator.print();
    generator.close();
  };

  handleDeleteUser = (id) => {
    const { order } = this.props.ord;
    let orrid = order.id;

    this.props.deleteItemOrderEdit(id, orrid);

    let { filteredData } = this.state;
    filteredData = filteredData.filter((user) => user.id !== id);
    this.setState({ filteredData, isShowDeleteUser: false, selectUser: {} });
  };

  handleDeleteClick = (id) => {
    const { order } = this.props.ord;
    let selectUser = order.order_items.find((user) => user.id === id);
    this.setState({ isShowDeleteUser: true, selectUser });
  };

  handleSetData = (data, searchInput) => {
    this.setState({ filteredData: data, searchInput });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  changetab = (id, item) => {
    let { ageRestriction, is_shown, orderstatusid } = this.state;
this.setState({orderidupdate : id})
    this.state.timerago = item.order_starting_time;
    this.setState({ timerago: item.order_starting_time });
    // if order accepted only once
    orderstatusid = item.order_status_id;
    this.setState({ orderstatusid: item.order_status_id });
    //----------
    // age restriction popup
    let ageres = item.order_items.find(
      (orderitems) => orderitems.item.is_age_restriction === true
    );

    if (ageres && !is_shown && item.order_status_id === 1) {
      this.setState({ ageRestriction: true, is_shown: true });
    }
    //-------------
    this.setState({ acceptedorder_id: item.order_status_id });
    if (id) {
      this.setState({ loaddata: true });
      this.props.getorder(id);
    }
  };

  handleCancelAgerestriction = () => {
    this.setState({ ageRestriction: false });
  };

  render() {
   console.log(this.props.auth)
   let name;
   let address;
   /*  let name =
      !isEmpty(this.props.auth) &&
      !isEmpty(this.props.auth.user) &&
     
      !isEmpty(this.props.auth.user.restaurant.name)
        ? this.props.auth.user.restaurant.name
        : ""; */
        
 /*    let address =
      !isEmpty(this.props.auth) &&
      !isEmpty(this.props.auth.user) &&
      !isEmpty(this.props.auth.user.restaurant.name)
        ? this.props.auth.user.restaurant
        : ""; */
    const {
      filteredData,
      searchInput,
      isShowDeleteUser,
      code,
      selectUser,
      order_status,
      order_status_id,
      orderstatusid,
      ageRestriction,
    } = this.state;
    const { order } = this.props.ord;
    const { orders } = this.props.ord;
    const { tables } = this.props.tab;
    let { orderstatuss } = this.props.ordsta;
    const { ordertable } = this.props.ord;
    const { isLoading } = this.props.errors;
    let { ordercancel } = this.props.ordsta;
    const { acceptedorders } = this.props.ord;
    let tabname = this.props.name;
    console.log("orders",orders);
    // Accepted & Completed
    orderstatuss = orderstatuss.filter((status_id) => status_id.id !== 1);
    orderstatuss = orderstatuss.filter((status_id) => status_id.id !== 5);
    orderstatuss = orderstatuss.filter((status_id) => status_id.id !== 6);
    orderstatuss = orderstatuss.filter((status_id) => status_id.id !== 3);

    // Cancel
    ordercancel = ordercancel.filter((status_id) => status_id.id !== 1);
    ordercancel = ordercancel.filter((status_id) => status_id.id !== 5);
    ordercancel = ordercancel.filter((status_id) => status_id.id !== 6);
    ordercancel = ordercancel.filter((status_id) => status_id.id !== 2);
    ordercancel = ordercancel.filter((status_id) => status_id.id !== 4);

    let orderprint =
      !isEmpty(order) && !isEmpty(order.order_items) ? order.order_items : [];

    let ordtab = ordertable.map((ordta) => {
      return ordta.restaurant_table_id;
    });

    let orderstatus = order.order_status_id;

    this.state.timerago = order.order_starting_time;

    let headerStyle = {
      height: 45,
      paddingTop: 15,
      fontWeight: "bold",
    };
    const columns = [
      {
        Header: "S.No",
        id: "row",
        maxWidth: 75,
        headerStyle: headerStyle,
        filterable: false,
        Cell: (row) => {
          return <div>{row.index + 1}</div>;
        },
      },
      {
        Header: "Name",
        accessor: "item.name",
        // maxWidth: 100,
        headerStyle: headerStyle,
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        //   maxWidth: 100,
        headerStyle: headerStyle,
      },
      {
        Header: "Instruction",
        accessor: "instruction",
        maxWidth: 250,
        headerStyle: headerStyle,
      },

      {
        Header: "Add On",

        // maxWidth: 75,
        headerStyle: headerStyle,
        filterable: false,
        Cell: (row) => {
          return (
            <div>
              {row.original.order_item_add_ons.map((ordd) => {
                return !isEmpty(ordd) &&
                  !isEmpty(ordd.item_add_on) &&
                  !isEmpty(ordd.item_add_on.add_on)
                  ? ordd.item_add_on.add_on.name
                  : "";
              })}
            </div>
          );
        },
      },

      {
        Header: "Status",

        maxWidth: 75,
        headerStyle: headerStyle,
        filterable: false,
        Cell: (row) => {
          return (
            <div>
              {row.original.is_active ? (
                <div className="badge badge-success">Available</div>
              ) : (
                <div className="badge badge-danger">Unavailable</div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Action",
        headerStyle: headerStyle,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: "center",
            },
          };
        },
        Cell: (props) => (
          <Fragment>
              {props.original.order_item_status ? (
              <button
                type="button"
                className="btn btn-success btn-icon btn-light"
                data-toggle="tooltip"
                title="Completed"
                style={{ marginLeft: "10px", backgroundColor : "#65C449"  }}
                onClick={(id) => this.handleActiveClick(props.original.id)}
                disabled={this.state.orderstatusid < 2}
              >
                <i class="mdi mdi-check
"></i>
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-success btn-icon btn-light"
                data-toggle="tooltip"
                title="Incomplete"
                style={{ marginLeft: "10px", backgroundColor : "#EE5B5B" }}
                onClick={(id) => this.handleActiveClick(props.original.id)}
                disabled={this.state.orderstatusid < 2}
              >
        {/*         <i class="mdi mdi-close


"></i> */}
              </button>
            )}
            <button
              type="button"
              className="btn btn-trash btn-danger btn-rounded btn-icon"
              data-toggle="modal"
              data-target="#modal-default"
              title="Delete"
              style={{ marginLeft: "10px" }}
              onClick={(id) => this.handleDeleteClick(props.original.id)}
            >
              <i class="mdi mdi-delete"></i>
            </button>
          </Fragment>
        ), // Custom cell components!
      },
    ];

    const fields = ["id", "name", "code"];
    const relationFields = {
      manu: ["name"],
    };
    let addon = [
      {id : 0, name : "cheese"}
    ]
    return (
      <Modal
        id="popup"
        key="popup"
        open={true}
        size={"large"}
        header={this.props.name}
        onClose={this.handleCancelPopUp}
      >
        <div>
          <div className="main-panel">
            <div>
              <section className="content">
                <div className="row">
                  <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card-body">
                      <p className="card-description">
                        <h4
                          className="card-title"
                          style={{ fontWeight: "bold" }}
                        >
                          Select Order Id
                        </h4>
                      </p>
                      {/*   {!isLoading ? ( */}

                      <div className="row">
                        
                       
                        
                        <Tabs   > 
                          <TabList  >
                           
                            {orders.map((item) => {
                              return [
                                <Tab   style={{backgroundColor  : item.order_status_id === 1 ? "#EE5B5B" : "#CBCB23", color : item.order_status_id === 1 ? "white" : "black"}}  
                               
                                  onClick={(id) =>
                                    this.changetab(item.id, item)
                                  }
                                >
                                  {item.dynamic_order_id}
                                </Tab>,
                              ];
                            })}
                          </TabList>
                        </Tabs>

                        <div class="table-responsive">
                          {!isLoading ? (
                            <div>
                              {this.state.loaddata ? (
                                <h4
                                  className="card-title"
                                  style={{ fontWeight: "bold" }}
                                >
                                  List of items
                                  <Moment
                                    style={{ float: "right" }}
                                    interval={30000}
                                    fromNow
                                  >

                                    {this.state.timerago}
                                  </Moment>
                                </h4>
                              ) : null}
                              {this.state.loaddata ? (
                                <Table
                                  data={
                                    filteredData.length > 0
                                      ? filteredData
                                      : searchInput
                                      ? filteredData
                                      : order.order_items
                                  }
                                  columns={columns}
                                />
                              ) : null}
                            </div>
                          ) : (
                            <Spinner />
                          )}
                        </div>
                      </div>
                      {/*  ) : (
                        <Spinner />
                      )} */}
                      <div class="row">
                        {this.state.loaddata ? (
                          <>
                            <>
                              {/* Accepted-------------- */}
                              <button
                                className={`btn btn-order btn-outline-facebook Accepted`}
                                value={2}
                                onClick={() => this.handleUpdateClick(2)}
                                disabled={this.state.orderstatusid === 2}
                              >
                                Accepted
                              </button>

                              {/* Completed--------------------- */}
                              <button
                                className={`btn btn-order btn-outline-facebook Completed`}
                                value={4}
                                onClick={() => this.handleUpdateClick(4)}
                                disabled={this.state.orderstatusid < 2}
                              >
                                Complete All
                              </button>
                              {/* Paid--------------------- */}
                              {/*  <button
                                  className={`btn btn-order btn-outline-facebook Paid`}
                                
                                  value={6}
                                  onClick={() =>
                                    this.handleUpdateClick(6)
                                  }
                                >
                                 Paid
                                </button> */}
                            </>
                          </>
                        ) : null}

                        {/* Print------------------------- */}
                        {this.state.loaddata ? (
                          <>
                          {/*   <button
                              // id="btnPrint"
                              class="hidden-print btn btn-order btn-outline-facebook Print"
                              //onClick={() => this.setState({receipt: true})}
                              onClick={() => this.printDiv("printarea")}
                              value="print a div!"
                            >
                              Customer copy
                            </button> */}
                            <button
                              //  id="btnPrint"
                              class="hidden-print btn btn-order btn-outline-facebook Print"
                              // onClick={window.print}
                              onClick={() => this.printDivkitt("printareakitt")}
                              value="print a div!"
                            >
                              Print
                            </button>
                            {/* Cancel------------------------------ */}
                            {ordercancel.map((menu, i) => (
                              <>
                                {this.state.acceptedorder_id !== 2 ? (
                                  <div
                                    style={{
                                      float: "right",
                                      margin: "auto",
                                      marginRight: "0",
                                    }}
                                  >
                                    <button
                                      className={`btn btn-order btn-outline-facebook Canceled`}
                                      key={i}
                                      value={menu.id}
                                      onClick={() =>
                                        this.handleUpdateClick(menu.id)
                                      }
                                    >
                                      {menu.id === 3 ? "Cancel" : null}
                                    </button>
                                    {/* Close------------------------------------ */}
                                    <button
                                      class="btn btn-order btn-outline-facebook Closed"
                                      onClick={this.handleCancelPopUp}
                                    >
                                      Close
                                    </button>
                                  </div>
                                ) : (
                                  <button
                                    class="btn btn-order btn-outline-facebook Closed"
                                    onClick={this.handleCancelPopUp}
                                    style={{
                                      float: "right",
                                      margin: "auto",
                                      marginRight: "0",
                                    }}
                                  >
                                    Close
                                  </button>
                                )}{" "}
                              </>
                            ))}{" "}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Kitchen receipt */}
                <div className="ticket" id="printareakitt">
                  <div>
                    <p
                      class="centered"
                      style={{ textAlign: "center", fontWeight: "bold" }}
                    >
                      {!isEmpty(order.user) ? order.user.name : ""}
                    </p>
                    <p>
                      Order Id :
                      {!isEmpty(order.user) ? (
                        <span style={{ float: "right" }}>
                          {order.dynamic_order_id}
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                    <p>
                      Table Name :
                      {!isEmpty(tabname) ? (
                        <span style={{ float: "right" }}> {tabname}</span>
                      ) : (
                        ""
                      )}
                    </p>
                    <p>
                      Open Date :
                      {!isEmpty(order.createdAt) ? (
                        <span style={{ float: "right" }}>
                          {order.createdAt}
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                    <table
                      style={{
                        padding: "3%",
                        borderBottom: "1px solid",
                        borderTop: "1px solid",
                      }}
                    >
                      <thead >
                        <tr>
                          <th class="quantity" style={{paddingRight : "8px"}}>S.N</th>
                          <th class="description" style={{borderLeft : "1px solid", borderRight :"1px solid"}}>Items</th>
                          <th class="price"  style={{paddingLeft : "8px"}}>Qty</th>
                        </tr>
                      </thead>
                      <tbody >
                        {orderprint.map((element, key) => {
                          return (
                            <tr >
                              <td class="quantity" style={{verticalAlign : "top", textAlign : "center"}} >{key + 1}</td>
                              <td class="description" style={{borderLeft : "1px solid", paddingBottom : "20px", borderRight :"1px solid", paddingLeft : "8px"}}>
                                <b>{element.item.name}</b> <br />
                          {element.instruction ?  <b>Inst. : </b> : null}{element.instruction} <br />
                              
                              
                          {element.order_item_add_ons.length > 0 ?  <b>Addon : </b> : null} {element.order_item_add_ons.map(addon => {
                              
                                 if(!isEmpty(addon) && !isEmpty(addon.item_add_on)){
                                   return   addon.item_add_on.add_on.name 
                                 }
                                
                               }

                               )} 
                              </td>
                              <td class="price" style={{textAlign : "center"}}>{element.quantity}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
               
                {/*--------- Customer Copy -----------------------*/}
               {/*  {this.props.auth.user.role_id === 5 ?
                <div className="ticket" id="printarea">
                  <div>
                    <p
                      class="centered"
                      style={{ textAlign: "center", fontWeight: "bold" }}
                    >
                      {name}
                      <br />
                    </p>
                    <p style={{ textAlign: "center", marginBottom: "10%" }}>
                      {address.address}
                    </p>
                  </div>

                  <p>
                    Name :
                    {!isEmpty(order.user) ? (
                      <div style={{ float: "right" }}> {order.user.name}</div>
                    ) : (
                      ""
                    )}
                  </p>
                  <p>
                    Order Id :
                    {!isEmpty(order.user) ? (
                      <div style={{ float: "right" }}>
                        {order.dynamic_order_id}
                      </div>
                    ) : (
                      ""
                    )}
                  </p>
                  <p>
                    Table Name :
                    {!isEmpty(tabname) ? (
                      <div style={{ float: "right" }}> {tabname}</div>
                    ) : (
                      ""
                    )}
                  </p>
                  <p>
                    {" "}
                    Open Date :{" "}
                    {!isEmpty(order.createdAt) ? (
                      <div style={{ float: "right" }}> {order.createdAt}</div>
                    ) : (
                      ""
                    )}
                  </p>
                  <p class="ordersummary" style={{ marginTop: "5%" }}>
                    Order Summary
                  </p>
                  <table
                    style={{
                      marginTop: "7%",
                      borderBottom: "1px solid",
                      width: "100%",
                    }}
                  >
                    <thead style={{ borderBottom: "1px solid", width: "100%" }}>
                      <tr>
                        <th class="quantity">S.N</th>
                        <th class="description">Items</th>
                        <th class="price">Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderprint.map((element, key) => {
                        return (
                          <tr>
                            <td class="quantity">{key + 1}</td>
                            <td class="description">
                              {element.item.name} <br />
                              {/* {element.instruction} */}
                              {/*   {element.order_item_add_ons(add => { return add})}{" "} 
                            </td>
                            <td class="price">{element.quantity}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <p
                    class="centered"
                    style={{ textAlign: "center", marginTop: "5%" }}
                  >
                    Have a nice day, Visit us again!
                    <br />
                    pay-clean.com
                  </p>
                </div> : null } */}
              </section>
            </div>
          </div>

          {isShowDeleteUser ? (
            <Orderitemdelete
              handleCancelDeleteGym={this.handleCancelDeleteUser}
              handleDeleteClick={this.handleDeleteUser}
              selectClass={selectUser}
            />
          ) : null}
          {ageRestriction ? (
            <Agerestrictionpopup
              handleCancelAgerestriction={this.handleCancelAgerestriction}
              tabname={this.props.name}
            />
          ) : null}
        </div>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => ({
  ord: state.ord,
  tab: state.tab,
  auth: state.auth,
  ordsta: state.ordsta,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getorder,
  updateOrder,
  getAllOrdersStatus,
  getAllOrdersItems,
  deleteItemOrderEdit,
  getAllTables,
  getAllOrdersCount,
  getAllAcceptedOrders,
  getAllOrders,
  updateIrdertem
})(OrderpopUp);
