import {
    GET_ALL_MENU,
    ADD_MENU,
    GET_MENU
  } from "../actions/types";
  
  const initialState = {
    
    menus:[],
    menu:{}
    
   
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_MENU:
        return {
          ...state,
         
          menus: action.payload
        };
        case ADD_MENU:
        return {
          ...state,
         
          menus: action.payload
        };
        case GET_MENU:
          return {
            ...state,
           
            menu: action.payload
          };

     
      default:
        return state;
    }
  }
  