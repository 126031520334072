import React from "react";
import ReactDOM from "react-dom";
import ModalContainer from "./modalContainer";

class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.rootSelector = document.getElementById("root-modal");
    this.container = document.createElement("div");
  }

  componentDidMount() {
      console.log(this.rootSelector)
    this.rootSelector.appendChild(this.container);
  }

  componentWillUnmount() {
    // if( ! this.props.open ){
    this.rootSelector.removeChild(this.container);
    // }
  }

  render() {
    return ReactDOM.createPortal(<ModalContainer {...this.props} />, this.container);
  }
}

export default Modal;