import axios from "axios";
//import { push } from "connected-react-router";

import { API_URI } from "../utils/config";
//import jwt_decode from "jwt-decode";

import {
  GET_ERRORS,
  GET_ALL_ORDER_ITEMS,
  GET_ORDER
} from "./types";



// Get all Items
export const getAllOrdersItems = (id) => async (dispatch, getState) => {

    axios
      .get(`${API_URI}/order_items`)
      .then(res => {
       
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error
          });
        } else {
          const { data } = res.data;
          dispatch({
            type: GET_ALL_ORDER_ITEMS,
            payload: data
          });
         
        }
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err
        });
      });
  };

    // Delete item in order edit
 export const deleteItemOrderEdit = (id, orrid) => (dispatch, getState) => {
    let orderitems = [...getState().ord.orderitems];
  
    axios
      .delete(`${API_URI}/order_items/${id}`)
      .then((res) => {
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error,
          });
        } else {
          orderitems = orderitems.filter((gym) => gym.id !== id);
          const { data } = res.data;
        
          dispatch({
            type: GET_ALL_ORDER_ITEMS,
            payload: orderitems,
          });
          dispatch(getorder(orrid)) 
        }
      })
      .catch((err) =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        })
      );
  };

   // Get single Order
export const getorder = (id) => (dispatch) => {
  
    axios
      .get(`${API_URI}/orders/${id}`)
      .then(async (res) => {
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error,
          });
        } else {
          const { data } = res.data;
  
          dispatch({
            type: GET_ORDER,
            payload: data,
          });
          
          
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.error.message,
        });
      
        
      });
  };


     // Update OrderItem
export const updateIrdertem = (itemData, id, history) => (dispatch, getState) => {

  let orderitems = [...getState().ord.orderitems];

  axios
    .put(`${API_URI}/order_items/${id}`, itemData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        console.log(data)
        orderitems = orderitems.map((rest) => {
          if (rest.id === data.id) {
            rest = { ...rest, ...data };
          }
          return rest;
        }); 

      
        dispatch({
          type: GET_ALL_ORDER_ITEMS,
          payload: orderitems,
        });
         dispatch(getorder(data.order_id))  
       
        
      }
    })
    .catch((err) => {
       
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      });
    });
};