import axios from "axios";
//import { push } from "connected-react-router";

import { API_URI } from "../utils/config";
//import jwt_decode from "jwt-decode";

import {
  GET_ERRORS,
  GET_ALL_TABLES,
  ADD_TABLE,
  CODE_TABLE,
  GET_TABLE
} from "./types";



// Get all Items
export const getAllTables = () => async dispatch => {
  let restaurant_id = await localStorage.getItem("restaurant_id");
    axios
      .get(`${API_URI}/restaurant_tables?filter={"where":{"restaurant_id":${restaurant_id}},"inOrder":{"order_status_id":{"$in":[1,2]}}}`)
      
      .then(res => {
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error
          });
        } else {
          const { data } = res.data;
          dispatch({
            type: GET_ALL_TABLES,
            payload: data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err
        });
      });
  };

    // Add Table 
export const addTable = (tableData, history) => (dispatch, getState) => {
    
    let tables = [...getState().tab.tables];
    axios
      .post(`${API_URI}/restaurant_tables`, tableData)
      .then((res) => {
       
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error,
          });
        } else {
          const { data } = res.data;
          
          tables.push(data);
         
          dispatch({
            type: ADD_TABLE,
            payload: tables,
          });
          dispatch({
            type: CODE_TABLE,
            payload: data,
          });
     //   history.push("/table");
          
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.error.message,
        });
        
      });
  };

   //  Delete Table
 export const deleteTable = (id) => (dispatch, getState) => {
    let tables = [...getState().tab.tables];
  
    axios
      .delete(`${API_URI}/restaurant_tables/${id}`)
      .then((res) => {
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error,
          });
        } else {
            tables = tables.filter((gym) => gym.id !== id);
          dispatch({
            type: GET_ALL_TABLES,
            payload: tables,
          });
        }
      })
      .catch((err) =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        })
      );
  };

     // Get single table Details in view and edit
export const getTable = (id) => (dispatch) => {
    
  axios
    .get(`${API_URI}/restaurant_tables/${id}`)
    .then(res => {
      
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
       const { data } = res.data;
        
        dispatch({
          type: GET_TABLE,
          payload: data,
        });
        
      }
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      });
      
    });
};

 // Update Table
 export const updateTable = (tabData, id, history) => (dispatch, getState) => {

  let tables = [...getState().tab.tables];

  axios
    .put(`${API_URI}/restaurant_tables/${id}`, tabData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        tables = tables.map((rest) => {
          if (rest.id === data.id) {
            rest = { ...rest, ...data };
          }
          return rest;
        }); 

         dispatch({
          type: GET_ALL_TABLES,
          payload: tables,
        }); 
        history.push("/table");
        
      }
    })
    .catch((err) => {
       
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      });
    });
};