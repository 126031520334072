import axios from "axios";
//import { push } from "connected-react-router";

import { API_URI } from "../utils/config";
//import jwt_decode from "jwt-decode";

import {
  GET_ERRORS,
  GET_ALL_ORDER_STATUS,
  GET_CANCEL_ORDER
} from "./types";



// Get all Items
export const getAllOrdersStatus = (id) => async (dispatch, getState) => {

    axios
      .get(`${API_URI}/order_status`)
      .then(res => {
        
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error
          });
        } else {
          const { data } = res.data;
          dispatch({
            type: GET_ALL_ORDER_STATUS,
            payload: data
          });
          dispatch({
            type: GET_CANCEL_ORDER,
            payload: data
          });
         
        }
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err
        });
      });
  };