import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import restaurantReducer from "./restaurantReducer";
import itemReducer from "./itemReducer";
import menuReducer from "./menuReducer";
import cuisinesReducer from "./cuisinesReducer";
import tableReducer from "./tableReducer";
import addonReducer from "./addonReducer";
import orderReducer from "./orderReducer";
import orderStatusReducer from "./orderStatusReducer"
import bankDetailsReducer from "./bankDetailsReducer"
import reportReducer from "./reportReducer";
import staffReducer from "./staffReducer";
import signupReducer from "./signupReducer";
export default combineReducers({
    auth: authReducer,
    errors:errorReducer,
    res:restaurantReducer,
    ite:itemReducer,
    men: menuReducer,
    cus:cuisinesReducer,
    tab:tableReducer,
    add:addonReducer,
    ord:orderReducer,
    ordsta: orderStatusReducer,
    ban: bankDetailsReducer,
    rep: reportReducer,
    stff : staffReducer,
    sign: signupReducer
})