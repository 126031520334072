export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const CLEAR_CURRENT_USER = "CLEAR_CURRENT_USER";
export const GET_ERRORS = "GET_ERRORS"
export const CLEAR_ERRORS = "CLEAR_ERRORS"
export const GET_USERS = "GET_USERS"
export const GET_ALL_RESTAURANTS = "GET_ALL_RESTAURANTS"
export const GET_SINGLE_RESTAURANT = "GET_SINGLE_RESTAURANT"
export const GET_USER = "GET_USER"
export const GET_STATS = "GET_STATS"
export const GET_RESTAURANT_VERIFICATION = "GET_RESTAURANT_VERIFICATION"
export const ADD_ITEM = "ADD_ITEM"
export const GET_ALL_ITEMS ="GET_ALL_ITEMS"
export const GET_ITEM ="GET_ITEM"
export const GET_ALL_MENU = "GET_ALL_MENU"
export const GET_ALL_CUISINES = "GET_ALL_CUISINES"
export const ADD_MENU = "ADD_MENU"
export const GET_MENU = "GET_MENU"
export const ADD_CUISINES = "ADD_CUISINES"
export const GET_CUISINE = "GET_CUISINE"
export const GET_ALL_TABLES = "GET_ALL_TABLES"
export const ADD_TABLE = "ADD_TABLE"
export const GET_TABLE = "GET_TABLE"
export const CODE_TABLE = "CODE_TABLE"
export const CLEAR_DATA = "CLEAR_DATA"
export const LOADING = "LOADING";
export const CLEAR_LOADING = "CLEAR_LOADING";
export const GET_STATS_RES = "GET_STATS_RES"
export const GET_ALL_ADDON = "GET_ALL_ADDON"
export const GET_ALL_ORDERS = "GET_ALL_ORDERS"
export const GET_ORDER = "GET_ORDER"
export const GET_ORDER_TABLE_NAME = "GET_ORDER_TABLE_NAME"
export const GET_ALL_ORDER_STATUS = "GET_ALL_ORDER_STATUS"
export const GET_ALL_ORDER_ITEMS = "GET_ALL_ORDER_ITEMS"
export const GET_RESTAURANT = "GET_RESTAURANT"
export const ADD_BANK_DETAILS = "ADD_BANK_DETAILS"
export const GET_ALL_REPORT = "GET_ALL_REPORT"
export const CONFIRM_MESSAGE = "CONFIRM_MESSAGE"
export const CONFIRM_MESSAGE_CLEAR = "CONFIRM_MESSAGE_CLEAR"
export const FORGOT_PASSWORD_OTP_VERIFICATION = "FORGOT_PASSWORD_OTP_VERIFICATION"
export const RESET_RESTAURANT_PASSWORD = "RESET_RESTAURANT_PASSWORD"
export const UPDATE = "UPDATE"
export const UPDATE_FALSE = "UPDATE_FALSE"
export const GET_ORDER_STATS = "GET_ORDER_STATS"
export const GET_ALL_STAFFS = "GET_ALL_STAFFS"
export const ADD_STAFF = "ADD_STAFF"
export const GET_STAFF = "GET_STAFF"
export const GET_ALL_GENDERS = "GET_ALL_GENDERS"
export const GET_ADDON = "GET_ADDON"
export const GET_CANCEL_ORDER = "GET_CANCEL_ORDER"
export const GET_ACCEPTED_ORDERS = "GET_ACCEPTED_ORDERS"
export const CLEAR_ADDON = "CLEAR_ADDON"
export const GET_ALL_SIGNUP = "GET_ALL_SIGNUP"
export const GET_ALL_SIGNUP_REJECT = "GET_ALL_SIGNUP_REJECT"
export const CONFIRM_MESSAGE_SIGNUP = "CONFIRM_MESSAGE_SIGNUP"
export const CONFIRM_MESSAGE_CLEAR_SIGNUP = "CONFIRM_MESSAGE_CLEAR_SIGNUP"
export const GET_RESTAURANT_REQUESTED_VIEW = "GET_RESTAURANT_REQUESTED_VIEW"
export const CLEAR_VIEW_DATA = "CLEAR_VIEW_DATA"
export const GET_ALL_ITEMS_CATEGORY ="GET_ALL_ITEMS_CATEGORY"
export const GET_ALL_TAXREPORT ="GET_ALL_TAXREPORT"
export const GET_RESTAURANT_TAX ="GET_RESTAURANT_TAX"
export const GET_ALL_STAFFREPORT ="GET_ALL_STAFFREPORT"

export const GET_ALL_RATINGREPORT ="GET_ALL_RATINGREPORT"
export const GET_ALL_ITEMSREPORT ="GET_ALL_ITEMSREPORT"
export const GET_ALL_ORDERS_EMPTY = "GET_ALL_ORDERS_EMPTY"
export const GET_EACH_ITEM_REPORT = "GET_EACH_ITEM_REPORT"
export const GET_ORDER_STATUS = "GET_ORDER_STATUS"


    