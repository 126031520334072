import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showme: false,
      dropdown: true,
      resdropdown: true,
      reportdropdown : true,
      restauranttaxreport : true
    };
  }

  dropdownfun = () => {
    const { dropdown } = this.state;

    this.setState({ dropdown: !this.state.dropdown });
  };

  resdropdown = () => {
    this.setState({ resdropdown: !this.state.resdropdown });
  };
  reportdropdown = () => {
    this.setState({ reportdropdown: !this.state.reportdropdown });
  };
  restaurantreportdropdown = () => {
    this.setState({ restauranttaxreport: !this.state.restauranttaxreport });
  };

  render() {
    const { user } = this.props.auth;
    const { restaurant } = this.props.res;

    const { dropdown, resdropdown, reportdropdown, restauranttaxreport } = this.state;
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        {user.role_id === 1 || user.role_id === 3 ? (
          <ul className="nav">
            <li className="headermain">MAIN NAVIGATION</li>
            <li className="nav-item">
              {/* <a className="nav-link">
                <Link to="/dashboard">
                  <i className="mdi mdi-file-document-box menu-icon"></i>
                  <span className="menu-title">Dashboard</span>{" "}
                </Link>
              </a> */}
              <NavLink
                className="nav-link"
                activeClassName="is-active"
                to="/dashboard"
                exact
              >
                <i className="mdi mdi-file-document-box menu-icon"></i>
                <span className="menu-title">Dashboard</span>
              </NavLink>
            </li>
          {/*  <Link to="/restaurants">
            <li className="nav-item">
            <a className="nav-link" href="" onClick={this.resdropdown}>
            <i className="mdi mdi-silverware-variant menu-icon"></i>
                    <span className="menu-title">Restaurants</span>
                    <i className="menu-arrow"></i>
                  </a>
          
            {resdropdown === false ? (
                    <ul className="sub-menu" style={{ lineHeight: "0px" }}>
                      <a>
                        {" "}
                        <Link className="menudropdown" to="/restaurantrequest">
                          <li className="nav-item">
                            <a className="nav-link">Restaurant Requested</a>
                          </li>
                        </Link>
                      </a>
                      </ul>
            ) : null }
              </li>
              </Link>  */} 
            <li className="nav-item">
              <NavLink
                className="nav-link"
                activeClassName="is-active"
                to="/restaurants"
                exact
              >
                <i className="mdi mdi-silverware-variant menu-icon"></i>
                <span className="menu-title">Restaurants</span>
              </NavLink> 
              {/*  <a className="nav-link">
                <Link to="/restaurants">
                <i className="mdi mdi-silverware-variant menu-icon"></i>
                  <span className="menu-title">Restaurants</span>
                </Link>
              </a>   */}
             </li>  
            <li className="nav-item">
              <a className="nav-link">
                <Link to="/users">
                  <i className="mdi mdi-account-multiple menu-icon"></i>
                  <span className="menu-title">Customers</span>
                </Link>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <Link to="/onboardingform">
                  <i className="mdi mdi-bank menu-icon"></i>
                  <span className="menu-title">Payment Onboarding</span>{" "}
                </Link>
              </a>
            </li>

          {/*   <li className="nav-item">
              <a className="nav-link">
                <Link to="/orderReport">
                  <i className="mdi mdi-finance menu-icon"></i>
                  <span className="menu-title">Reports</span>
                </Link>
              </a>
            </li> */}

            <Link to="/orderReport">
            <li className="nav-item">
            <a className="nav-link" href="" onClick={this.reportdropdown}>
            <i className="mdi mdi-silverware-variant menu-icon"></i>
                    <span className="menu-title">Reports</span>
                    <i className="menu-arrow"></i>
                  </a>
          
            {reportdropdown === false ? (
                    <ul className="sub-menu" style={{ lineHeight: "0px" }}>
                      <a>
                        {" "}
                        <Link className="menudropdown" to="/taxreport">
                          <li className="nav-item">
                            <a className="nav-link">Tax Report</a>
                          </li>
                        </Link>
                      </a>
                      </ul>
            ) : null }
              </li>
              </Link> 
            <li className="nav-item">
              <a className="nav-link">
                <i className="mdi mdi-gift menu-icon"></i>
                <span className="menu-title">Rewards</span>
              </a>
            </li>

            <li className="nav-item">
              <a
                className="nav-link"
                style={{ cursor: "pointer" }}
                onClick={this.dropdownfun}
              >
                <i className="mdi mdi-database menu-icon"></i>
                <span className="menu-title">Master</span>

                <i className="menu-arrow"></i>
              </a>
              {dropdown === false ? (
                <ul className="sub-menu">
                  <li className="nav-item">
                    {" "}
                    <a className="nav-link">
                      <Link className="menudropdown" to="/menu">
                        Menu Category{" "}
                      </Link>{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <a className="nav-link">
                      <Link className="menudropdown" to="/cuisines">
                        Cuisines{" "}
                      </Link>
                    </a>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <a className="nav-link">
                      <Link className="menudropdown" to="/addon">
                        Add On{" "}
                      </Link>
                    </a>
                  </li>
                </ul>
              ) : null}
            </li>
          </ul>
        ) : user.role_id === 4 ? (
          <ul className="nav">
            <li className="headermain">MAIN NAVIGATION</li>
            <a>
              {" "}
              <Link to="/orders">
                {" "}
                <li className="nav-item">
                  <a className="nav-link" href="" onClick={this.resdropdown}>
                    <i className="mdi mdi-cart menu-icon"></i>
                    <span className="menu-title">Orders</span>
                    <i className="menu-arrow"></i>
                  </a>
                  {resdropdown === false ? (
                    <ul className="sub-menu" style={{ lineHeight: "0px" }}>
                      <a>
                        {" "}
                        <Link className="menudropdown" to="/acceptedorder">
                          <li className="nav-item">
                            <a className="nav-link">Accepted Orders</a>
                          </li>
                        </Link>
                      </a>
                      <Link className="menudropdown" to="/vieworderstatus">
                        <li className="nav-item">
                          <a className="nav-link">View Orders Status</a>
                        </li>
                      </Link>
                      <Link className="menudropdown" to="/orderhistory">
                        <li className="nav-item">
                          <a className="nav-link">Order History</a>
                        </li>
                      </Link>
                    </ul>
                  ) : null}
                </li>{" "}
              </Link>{" "}
            </a>
            <li className="nav-item">
              <a className="nav-link">
                <Link to="/table">
                  <i className="mdi mdi-table-large menu-icon"></i>
                  <span className="menu-title">Table</span>{" "}
                </Link>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <Link to="/items">
                  <i className="mdi mdi-menu menu-icon"></i>
                  <span className="menu-title">Menu</span>{" "}
                </Link>
              </a>
            </li>
            <Link to="/orderReport">
            <li className="nav-item">
            <a className="nav-link" href="" onClick={this.restaurantreportdropdown}>
            <i className="mdi mdi-silverware-variant menu-icon"></i>
                    <span className="menu-title">Reports</span>
                    <i className="menu-arrow"></i>
                  </a>
          
            {restauranttaxreport === false ? (
                    <ul className="sub-menu" style={{ lineHeight: "0px" }}>
                      <a>
                        {" "}
                        <Link className="menudropdown" to="/restauranttaxreport">
                          <li className="nav-item">
                            <a className="nav-link">Tax Report</a>
                          </li>
                        </Link>
                      </a>
                      <a>
                        {" "}
                        <Link className="menudropdown" to="/staffreport">
                          <li className="nav-item">
                            <a className="nav-link">Staff Report</a>
                          </li>
                        </Link>
                      </a>
                      <a>
                        {" "}
                        <Link className="menudropdown" to="/ratingreport">
                          <li className="nav-item">
                            <a className="nav-link">Rating Report</a>
                          </li>
                        </Link>
                      </a>
                      </ul>
            ) : null }
              </li>
              </Link> 
           {/*  <li className="nav-item">
              <a className="nav-link">
                <Link to="/orderReport">
                  <i className="mdi mdi-finance menu-icon"></i>
                  <span className="menu-title">Reports</span>
                </Link>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <Link to="/restauranttaxreport">
                  <i className="mdi mdi-finance menu-icon"></i>
                  <span className="menu-title">Tax Reports</span>
                </Link>
              </a>
            </li> */}
            <li className="nav-item">
              <a className="nav-link">
                <Link to="/onboardingform">
                  <i className="mdi mdi-bank menu-icon"></i>
                  <span className="menu-title">Payment Onboarding</span>{" "}
                </Link>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <Link to="/staff">
                  <i className="mdi mdi-account-multiple menu-icon"></i>
                  <span className="menu-title">Staff</span>{" "}
                </Link>
              </a>
            </li>
          </ul>
        ) : null}
      </nav>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  res: state.res,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(SideMenu);
