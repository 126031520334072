import React, { Component, Fragment } from "react";
import Menu from "../headerMenu";
import {
  getAllOrders,
  getorder,
  getAllOrdersCount,
  
  getAllAcceptedOrders,
} from "../../actions/orderAction";
import Moment from "react-moment";
import { getAllTables } from "../../actions/tableAction";
import { connect } from "react-redux";
import Table from "../common/Table";
import { IMAGE_URI } from "../../utils/config";
import PopUp from "./popUpEditOrder";
//import "../../../node_modules/react-table/react-table.css"
import Search from "../common/Search/index";
import Body from "../Body/index";
import Sidemenu from "../Sidemenu";
import { Link } from "react-router-dom";
import firebase from "../../firbase";
import isEmpty from "../../validation/is-empty";
import Editorder from "./editOrder";
import Check from "../../check";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Orderpopup from "./acceptOrderPopup";
import Topmenu from "../Sidemenu/topMenu";

import { FullScreen, useFullScreenHandle } from "react-full-screen";
var QRCode = require("qrcode.react");

class Order extends Component {
  intervalID;
  constructor(props) {
    super(props);
    this.state = {
      searchInput: "",
      filteredData: [],

      tableplaced: {},
      orderstats: [],
      orderstart: "",
      kitchenorder: [],
      barorder: [],
      acceptid: 1,
      isShowViewUser: false,
      selectUser: {},
    };
  }

  componentDidMount() {
    this.props.getAllAcceptedOrders();
   
  }

  handleSetData = (data, searchInput) => {
    this.setState({ filteredData: data, searchInput });
  };
  handleViewClick = (id) => {
    const { acceptedorders } = this.props.ord;
    let selectUser = acceptedorders.find((user) => user.id === id);
    console.log(selectUser);
    this.setState({ isShowViewUser: true, selectUser });
  };
  handleCancelViewUser = () => {
    this.setState({ isShowViewUser: false, selectUser: {} });
  };
  changetab = (id) => {
    const { acceptedorders, barorders, kitchenorders } = this.props.ord;
    this.setState({ acceptid: id });
    console.log(acceptedorders);

    if (id === 1) {
    } else if (id === 2) {
      let fff = acceptedorders.map((element) => {
        return {
          ...element,
          order_items: element.order_items.filter(
            (subElement) => subElement.item.is_bar_item === false
          ),
        };
      });
      let filtered = fff.filter(function (el) {
        return el.order_items.length > 0;
      });
      this.setState({ kitchenorder: filtered });
    } else if (id === 3) {
      let fff = acceptedorders.map((element) => {
        return {
          ...element,
          order_items: element.order_items.filter(
            (subElement) => subElement.item.is_bar_item === true
          ),
        };
      });

      let filtered = fff.filter(function (el) {
        return el.order_items.length > 0;
      });

      this.setState({ barorder: filtered });
    }
  };

  render() {
    const {
      filteredData,
      searchInput,
      isShowDeleteUser,
      code,
      selectUser,
      orderstats,
      isShowViewUser,
      acceptid,
    } = this.state;

    const { acceptedorders, barorders, kitchenorders } = this.props.ord;
    

    const user = [
      {
        id: 1,
        order_id: 32456,
        name: "John",
        order_status: "Dining",
        amount: "$25",
      },
    ];

    let headerStyle = {
      height: 45,
      paddingTop: 15,
      fontWeight: "bold",
    };
    const columns = [
      {
        Header: "Order Id",
        accessor: "dynamic_order_id",
        maxWidth: 140,
        headerStyle: headerStyle,
      },
      {
        Header: "Customer Name",
        accessor: "user.name",
        //   maxWidth: 100,
        headerStyle: headerStyle,
      },
      {
        Header: "Item Count",
        filterable: false,
        //   maxWidth: 100,
        headerStyle: headerStyle,
        Cell: (row) => {
          return <div>{row.original.order_items.length}</div>;
        },
      },
      {
        Header: "Item Name",
        filterable: false,
        //   maxWidth: 100,
        headerStyle: headerStyle,
        Cell: (row) => {
          return (
            <div>
              {row.original.order_items.map((orderitem, i) => {
                return [
                  <div key={i + 1}>
                    {i + 1} - {orderitem.item.name}
                  </div>,
                ];
              })}
            </div>
          );
        },
      },
      {
        Header: "Add On",

        // maxWidth: 75,
        headerStyle: headerStyle,
        filterable: false,
        Cell: (row) => {
          return (
            <div>
              {row.original.order_items.map((ordd) => {
                return !isEmpty(ordd) && !isEmpty(ordd.order_item_add_ons)
                  ? ordd.order_item_add_ons.map((orderr) => {
                      return !isEmpty(orderr) &&
                        !isEmpty(orderr.item_add_on) &&
                        !isEmpty(orderr.item_add_on.add_on)
                        ? orderr.item_add_on.add_on.name
                        : "";
                    })
                  : null;
              })}
            </div>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "total_price",
        //   maxWidth: 100,
        headerStyle: headerStyle,
      },

      {
        Header: "Order Accepted",

        // maxWidth: 75,
        headerStyle: headerStyle,
        filterable: false,
        Cell: (row) => {
          return (
            <div>
              <Moment interval={30000} fromNow>
                {row.original.order_starting_time}
              </Moment>
            </div>
          );
        },
      },
      {
        Header: "Action",
        headerStyle: headerStyle,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: "center",
            },
          };
        },
        Cell: (props) => (
          <Fragment>
            <button
              type="button"
              className="btn btn-edit btn-success btn-rounded btn-icon"
              data-toggle="modal"
              data-target="#modal-default"
              title="View"
              style={{ marginLeft: "10px" }}
              onClick={(id) => this.handleViewClick(props.original.id)}
            >
              <i className="mdi mdi-information-variant"></i>
            </button>
          </Fragment>
        ), // Custom cell components!
      },
    ];

    const fields = ["dynamic_order_id", "total_price"];
    const relationFields = {
      user: ["name"],
    };

    const tabbs = [
      { name: "All", id: 1 },
      { name: "Kitchen Order", id: 2 },
      { name: "Bar Order", id: 3 },
    ];

    return (
      <div>
        <Body>
          <div className="container-scroller">
            <Menu />

            <div className="container-fluid page-body-wrapper">
              {this.props.auth.user.role_id === 5 ? "" : <Sidemenu />}

              <div className="main-panel" id="myvideo">
                <div
                  className="content-wrapper"
                  style={{
                    padding:
                      this.props.auth.user.role_id === 5 ? "12px" : "1.5rem 0",
                  }}
                >
                  <section className="content">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row">
                          {this.props.auth.user.role_id === 5 ? (
                            <Topmenu active="acceptedorder" />
                          ) : null}
                          <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card-body">
                              {/*    <h4 className="card-title">Accepted Orders</h4> */}
                              <p className="card-description"></p>

                              <p className="card-description"></p>
                              {/*  <div className="nav-item nav-search d-none d-lg-block ml-3">
                            <div className="dataTables_filter">
                              <div
                                style={{ float: "right", marginBottom: "10px" }}
                              >
                                <Search
                                  data={acceptedorders}
                                  handleSetData={this.handleSetData}
                                  fields={fields}
                                  relationFields={relationFields}
                                />
                              </div>
                            </div>
                          </div> */}

                              <Tabs>
                                <TabList>
                                  {tabbs.map((item) => {
                                    return [
                                      <Tab
                                        onClick={(id) =>
                                          this.changetab(item.id)
                                        }
                                      >
                                        {item.name}
                                      </Tab>,
                                    ];
                                  })}
                                </TabList>
                              </Tabs>

                              <div className="table-responsive">
                                {this.state.acceptid === 1 ? (
                                  <Table
                                    data={
                                      filteredData.length > 0
                                        ? filteredData
                                        : searchInput
                                        ? filteredData
                                        : acceptedorders
                                    }
                                    columns={columns}
                                  />
                                ) : this.state.acceptid === 2 ? (
                                  <Table
                                    data={
                                      filteredData.length > 0
                                        ? filteredData
                                        : searchInput
                                        ? filteredData
                                        : this.state.kitchenorder
                                    }
                                    columns={columns}
                                  />
                                ) : (
                                  <Table
                                    data={
                                      filteredData.length > 0
                                        ? filteredData
                                        : searchInput
                                        ? filteredData
                                        : this.state.barorder
                                    }
                                    columns={columns}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row"></div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </Body>
        {isShowViewUser ? (
          <Orderpopup
            handlepopUpOpen={this.handleCancelViewUser}
            handleCancelPopUp={this.handleCancelViewUser}
            selectUser={selectUser}
            acceptid={acceptid}
          />
        ) : null}
        {/* <Editorder 
          tabname={this.state.tablename} /> */}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  ord: state.ord,
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {

  getAllAcceptedOrders,
})(Order);
