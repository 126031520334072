import {
    GET_ALL_REPORT,
    GET_ALL_TAXREPORT,
    GET_RESTAURANT_TAX,
    GET_ALL_STAFFREPORT,
    GET_ALL_RATINGREPORT,
    GET_ALL_ITEMSREPORT,
    GET_EACH_ITEM_REPORT
  } from "../actions/types";
  
  const initialState = {
    
    reports:[],
   tax: [],
   restauranttax : {},
   staffreport : [],
   ratingreport : [],
   itemreport : [],
   itemratingreport : []
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_REPORT:
        return {
          ...state,
         
          reports: action.payload
        };
        case GET_ALL_TAXREPORT:
        return {
          ...state,
         
          tax: action.payload
        };
        case GET_RESTAURANT_TAX:
          return {
            ...state,
           
            restauranttax: action.payload
          };
          case GET_ALL_STAFFREPORT:
            return {
              ...state,
             
              staffreport: action.payload
            };
            case GET_ALL_RATINGREPORT:
            return {
              ...state,
             
              ratingreport: action.payload
            };

            case GET_ALL_ITEMSREPORT:
              return {
                ...state,
               
                itemreport: action.payload
              };
              
            case GET_EACH_ITEM_REPORT:
              return {
                ...state,
               
                itemratingreport: action.payload
              };
     
      default:
        return state;
    }
  }
  