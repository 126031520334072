import {
    GET_ERRORS,
    LOADING,
    CLEAR_LOADING,
    CLEAR_ERRORS,
    CONFIRM_MESSAGE,
    CONFIRM_MESSAGE_CLEAR,
    UPDATE,
    UPDATE_FALSE,
    CONFIRM_MESSAGE_CLEAR_SIGNUP,
    CONFIRM_MESSAGE_SIGNUP
  } from "../actions/types";
  
  const initialState = {
    error: "",
    isLoading: false,
    message: false,
    update : true,
    signupform : false
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case GET_ERRORS:
        return {
          ...state,
          error: action.payload
        };
        case CLEAR_ERRORS:
          return {
            ...state,
            error: ""
          };
        case LOADING:
          return {
            ...state,
            isLoading: true
          };
        case CLEAR_LOADING:
          return {
            ...state,
            isLoading: false
          };
          case CONFIRM_MESSAGE:
            return {
              ...state,
              message: true
            };
            case CONFIRM_MESSAGE_CLEAR:
            return {
              ...state,
              message: false
            };
            case UPDATE:
              return {
                ...state,
                update: true
              };
              case UPDATE_FALSE:
                return {
                  ...state,
                  update: false
                };
                case CONFIRM_MESSAGE_SIGNUP:
            return {
              ...state,
              signupform: true
            };
            case CONFIRM_MESSAGE_CLEAR_SIGNUP:
            return {
              ...state,
              signupform: false
            };
  
    
      default:
        return state;
    }
  }
  