import {
    GET_ALL_CUISINES,
    ADD_CUISINES,
    GET_CUISINE
  } from "../actions/types";
  
  const initialState = {
    
    cuisines:[],
    cuisine:{}
    
   
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_CUISINES:
        return {
          ...state,
         
          cuisines: action.payload
        };
        case ADD_CUISINES:
          return {
            ...state,
           
            cuisines: action.payload
          };
          case GET_CUISINE:
            return {
              ...state,
             
              cuisine: action.payload
            };

     
      default:
        return state;
    }
  }
  