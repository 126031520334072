import {
    GET_ALL_STAFFS,
    ADD_STAFF,
    GET_STAFF,
    GET_ALL_GENDERS
  } from "../actions/types";
  
  const initialState = {
    
    staffs:[],
    staff: {},
    genders : []
    
   
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_STAFFS:
        return {
          ...state,
         
          staffs: action.payload
        };
        case ADD_STAFF:
            return {
              ...state,
             
              staffs: action.payload
            };
            case GET_STAFF:
                return {
                  ...state,
                 
                  staff: action.payload
                };
                case GET_ALL_GENDERS:
                return {
                  ...state,
                 
                  genders: action.payload
                };
       
       
     
      default:
        return state;
    }
  }
  