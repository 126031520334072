import React from "react";

const Body = props => {

  return (
    

  <div className="sidebar-dark">{props.children}</div>

   
  )
};

export default Body;