import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Body from "../Body/index";
import Box from "../Body/box";
import { IMAGE_URI } from "../../utils/config";
import { logoutUser } from "../../actions/authAction";
import isEmpty from "../../validation/is-empty";

class HeaderMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: false,
      Notificationdropdown: false,
      sidebar: false,
    };
  }

  dropdownfun = () => {
    const { dropdown } = this.state;

    this.setState({ dropdown: !this.state.dropdown });
  };

  Notificationdropdownfun = () => {
    const { Notificationdropdown } = this.state;

    this.setState({ Notificationdropdown: !this.state.Notificationdropdown });
  };
  render() {
    const { dropdown, Notificationdropdown } = this.state;

    const { user } = this.props.auth;
    let id =
      !isEmpty(user) && !isEmpty(user.restaurant) ? user.restaurant.id : "";
    /*   const { id } = this.props.auth.user.restaurant; */
    let name =
      !isEmpty(user) && !isEmpty(user.restaurant)
        ? user.restaurant.name
        : "Payclean Admin";
    let staffname = !isEmpty(user) ? user.name : "Payclean Admin";
    let restaurantlogo =
      !isEmpty(user) &&
      !isEmpty(user.restaurant) &&
      !isEmpty(user.restaurant.attachments)
        ? user.restaurant.attachments
        : [];

    let restaurantHeaderlogo = restaurantlogo.find(
      (restaurantlogo) => restaurantlogo.class === "Logo"
    );

    return (
      <div>
        <Body sidebar={true}>
          <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
              <a className="navbar-brand brand-logo">
                <img
                  src={require("../../assests/images/logo.png")}
                  alt="logo"
                />
              </a>
              <a className="navbar-brand brand-logo-mini">
                <img src="../../assests/images/logo.png" alt="logo" />
              </a>
            </div>
            <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
              {/*  <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
          <span className="mdi mdi-menu"></span>
        </button> */}
            {/*   {this.props.auth.user.role_id === 5 ? (
                <>
                <button className="btn btn-inverse-light btn-fw">
                <Link to={`/orders`}> Home</Link>{" "}
            
               </button>
                <button className="btn btn-inverse-light btn-fw">
                  <Link to={`/staffmenu`}> Menu</Link>{" "}
             
                </button>
                <button className="btn btn-inverse-light btn-fw">
                <Link to={`/orderhistory`}>Orders</Link>{" "}
            
               </button>
                
               <button className="btn btn-inverse-light btn-fw">
                <Link to={`/acceptedorder`}>Accepted Orders</Link>{" "}
            
               </button> 
               
               </>
                     
              ) : null} */}
              <ul className="navbar-nav navbar-nav-right">
                <li className="nav-item nav-profile dropdown show">
                  <a
                    className="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    id="profileDropdown-navbar"
                    onClick={this.dropdownfun}
                    aria-expanded={dropdown}
                  >
                    <span className="nav-profile-name">
                      {user.role_id === 5 ? staffname : name}
                    </span>
                    <span className="online-status"></span>
                    {user.role_id === 1 ? (
                      <img
                        src={require("../../assests/images/logoadmin.ico")}
                        alt="profile"
                      />
                    ) : !isEmpty(restaurantHeaderlogo) ? (
                      <img
                        src={`${IMAGE_URI}/${restaurantHeaderlogo.dir}/${restaurantHeaderlogo.file_name}`}
                      />
                    ) : (
                      ""
                    )}
                  </a>
                  {dropdown ? (
                    <div
                      className="dropdown-menu dropdown-menu-right navbar-dropdown show"
                      aria-labelledby="profileDropdown-navbar"
                    >
                      {user.role_id === 4 ? (
                        <a className="dropdown-item">
                          {" "}
                          <Link to={`/restaurantedit/${btoa(id)}`}>
                            <i className="mdi mdi-settings text-primary"></i>
                            Profile
                          </Link>
                        </a>
                      ) : (
                        <a
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                        >
                          <i className="mdi mdi-settings text-primary"></i>
                          <Link to={`/changepassword`}> Change Password</Link>
                        </a>
                      )}
                      <a
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.props.logoutUser()}
                      >
                        <i className="mdi mdi-logout text-primary"></i>
                        Logout
                      </a>
                    </div>
                  ) : null}
                </li>
              </ul>

              {/*  {user.role_id === 4 ? 
        <ul class="navbar-nav navbar-nav-left">
      
              <li class="nav-item dropdown show">
                <a class="nav-link count-indicator dropdown-toggle d-flex align-items-center justify-content-center"  id="notificationDropdown" data-toggle="dropdown" onClick={this.Notificationdropdownfun} aria-expanded={Notificationdropdown}>
                  <i class="mdi mdi-bell mx-0"></i>
                  <span class="count bg-success">2</span>
                </a>
                { Notificationdropdown  ?
                <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list show" aria-labelledby="notificationDropdown" >
                  <p class="mb-0 font-weight-normal float-left dropdown-header">Notifications</p>
                  <a class="dropdown-item preview-item">
                    <div class="preview-thumbnail">
                        <div class="preview-icon bg-success">
                          <i class="mdi mdi-information mx-0"></i>
                        </div>
                    </div>
                    <div class="preview-item-content">
                        <h6 class="preview-subject font-weight-normal">Orders Received</h6>
                        <p class="font-weight-light small-text mb-0 text-muted">
                          Just now
                        </p>
                    </div>
                  </a>
                  <a class="dropdown-item preview-item">
                    <div class="preview-thumbnail">
                        <div class="preview-icon bg-warning">
                          <i class="mdi mdi-settings mx-0"></i>
                        </div>
                    </div>
                    <div class="preview-item-content">
                        <h6 class="preview-subject font-weight-normal">Order two</h6>
                        <p class="font-weight-light small-text mb-0 text-muted">
                        
                        </p>
                    </div>
                  </a>
               
                </div> : null }
              </li>
             
             
            </ul> : null } */}
              <button
                className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
                type="button"
                data-toggle="offcanvas"
              >
                <span className="mdi mdi-menu"></span>
              </button>
            </div>
            
          </nav>
        </Body>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,

  errors: state.errors,
});

export default connect(mapStateToProps, { logoutUser })(HeaderMenu);
