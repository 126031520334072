import { GET_ALL_SIGNUP, GET_ALL_SIGNUP_REJECT, GET_RESTAURANT_REQUESTED_VIEW, CLEAR_VIEW_DATA } from "../actions/types";

const initialState = {
  signup: [],
  signupreject: [],
  signupsingledata: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SIGNUP:
      return {
        ...state,

        signup: action.payload,
      };
      case GET_ALL_SIGNUP_REJECT:
        return {
          ...state,
  
          signupreject: action.payload,
        };
        case GET_RESTAURANT_REQUESTED_VIEW:
        return {
          ...state,
  
          signupsingledata: action.payload,
        };
        case CLEAR_VIEW_DATA:
          return {
            ...state,
    
            signupsingledata: action.payload,
          };

    default:
      return state;
  }
}
