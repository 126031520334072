import {
    GET_ALL_ADDON,
    GET_ADDON,
    CLEAR_ADDON
  } from "../actions/types";
  
  const initialState = {
    
    addons:[],
    addon:{}
    
   
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_ADDON:
        return {
          ...state,
         
          addons: action.payload
        };
        case GET_ADDON:
          return {
            ...state,
           
            addon: action.payload
          };
        
          case CLEAR_ADDON:
            return {
              ...state,
             
              addon: {}
            };
     
      default:
        return state;
    }
  }
  