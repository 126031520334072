import {
    ADD_ITEM,
    GET_ALL_ITEMS,
    GET_ITEM,
    CLEAR_DATA,
    GET_ALL_ITEMS_CATEGORY
    
  } from "../actions/types";
  
  const initialState = {
    
    items:[],
    item:{},
    itemscategory : []
   
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_ITEMS:
        return {
          ...state,
         
         items: action.payload
        };

      case ADD_ITEM:
        return {
          ...state,
         
         items: action.payload
        };
        case GET_ITEM:
            return {
              ...state,
             
              item: action.payload
            };
           case CLEAR_DATA:
              return {
                ...state,
               
                item: {}
              };
              case GET_ALL_ITEMS_CATEGORY:
                return {
                  ...state,
                 
                  itemscategory: action.payload
                };
               
   
      default:
        return state;
    }
  }
  