import React, { Component, Fragment } from "react";
import Menu from "../headerMenu";
import Modal from "../common/Modal/index";
import {
  getorder,
  updateOrder,
  getAllAcceptedOrders,
  getAllOrders,
} from "../../actions/orderAction";
import {

    updateIrdertem
  
  } from "../../actions/orderItems";
import {
  deleteItemOrderEdit,
  getAllOrdersItems,
} from "../../actions/orderItems";
import Agerestrictionpopup from "./ageRestrictedPop";
import Moment from "react-moment";
import { getAllOrdersCount } from "../../actions/orderAction";
import { getAllTables } from "../../actions/tableAction";
import { getAllOrdersStatus } from "../../actions/orderStatusAction";
import { connect } from "react-redux";
import Table from "../common/Table";
import { IMAGE_URI } from "../../utils/config";
//import "../../../node_modules/react-table/react-table.css"
import Search from "../common/Search/index";
import Body from "../Body/index";
import Sidemenu from "../Sidemenu";
import { Link } from "react-router-dom";
import Orderitemdelete from "./orderItemDelete";
import isEmpty from "../../validation/is-empty";

import Spinner from "../common/Spinner/index";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var QRCode = require("qrcode.react");

class OrderpopUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchInput: "",
      filteredData: [],
      isShowDeleteUser: false,

      selectorder: {},
      order_status: "",
      id: "",
      order_status_id: "",
      orderid: 0,
      starttime: 0,
      setSeconds: [],
      timerago: "",
      loaddata: false,
      acceptedorder_id: 0,
      ageRestriction: false,
      statusupdateid: false,
      is_shown: false,
      orderstatusid: "",
      receipt: false,
      receiptkitchen: true,
      orderitem : []
    };
  }

  componentDidMount() {
    console.log(this.props.acceptid);
    const { selectUser } = this.props;
    this.props.getAllOrders();
    this.props.getAllOrdersStatus();
    this.props.getAllOrdersItems();
    this.props.getorder(this.props.selectUser.id);
    let reduced = []
   if(this.props.acceptid === 3){
    selectUser.order_items.forEach(o => {
        o.item.is_bar_item && reduced.push(o);
        } );
        this.setState({orderitem : reduced})
   } else if(this.props.acceptid === 2){
    selectUser.order_items.forEach(o => {
        !o.item.is_bar_item && reduced.push(o);
        } );
        this.setState({orderitem : reduced})
   } else{
    this.setState({orderitem : selectUser.order_items})
   }
  


        
  }
     

  handleCancelPopUp = () => {
    this.props.handleCancelPopUp();
  };

  handleUpdateClick = (id) => {
    const { isclick } = this.state;
    if (id === 2) {
      toast.success("Your Order is Accepted !", {
        position: "top-center",
        closeOnClick: true,
      });
    } else if (id === 3) {
      toast.error("Your Order is Cancelled !", {
        position: "top-center",
      });
    } else if (id === 4) {
      toast.success("Your Order is Completed !", {
        position: "top-center",
      });
    }

    this.props.updateOrder(
      { order_status_id: id },
      this.props.ord.order.id,
      this.props.history
    );
    this.handleCancelPopUp();
    if (id === 4) {
      this.props.getAllTables();
    }
  };

  /*  PrintReceipt = (copytype) => {
    console.log(copytype);
    console.log(this.state.receipt);
    /// 1 kitchen
    // 2 customer copy
    if (copytype === 1) {
      this.setState({ receipt: true });
      console.log(this.state.receipt);
    }
    window.print();
  };

  Printkitchen = (copytype) => {
    console.log(copytype);
    if (copytype === 2) {
      this.setState({ receiptkitchen: true });
      console.log(this.state.receiptkitchen);
    }
    window.print();
  }; */

  printDiv = (divName) => {
    var generator = window.open(divName, "PRINT", "height=400,width=600");
    var printContents = document.getElementById(divName);
    generator.document.write(printContents.innerHTML.replace("Print Customer"));
    generator.document.close();
    generator.focus(); // necessary for IE >= 10*/
    generator.print();
    generator.close();
  };
  printDivkitt = (divName) => {
    console.log(divName);
    var generator = window.open(divName, "PRINT", "height=400,width=600");
    var printContents = document.getElementById(divName);
    generator.document.write(printContents.innerHTML.replace("Print Me"));
    generator.document.close();
    generator.focus(); // necessary for IE >= 10*/
    generator.print();
    generator.close();
  };

  handleSetData = (data, searchInput) => {
    this.setState({ filteredData: data, searchInput });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  changetab = (id, item) => {
    let { ageRestriction, is_shown, orderstatusid } = this.state;

    this.state.timerago = item.order_starting_time;
    this.setState({ timerago: item.order_starting_time });
    // if order accepted only once
    orderstatusid = item.order_status_id;
    this.setState({ orderstatusid: item.order_status_id });
    //----------
    // age restriction popup
    let ageres = item.order_items.find(
      (orderitems) => orderitems.item.is_age_restriction === true
    );

    if (ageres && !is_shown && item.order_status_id === 1) {
      this.setState({ ageRestriction: true, is_shown: true });
    }
    //-------------
    this.setState({ acceptedorder_id: item.order_status_id });
    if (id) {
      this.setState({ loaddata: true });
      this.props.getorder(id);
    }
  };

  handleCancelAgerestriction = () => {
    this.setState({ ageRestriction: false });
  };
  printDivkitt = (divName) => {
    console.log(divName);
    var generator = window.open(divName, "PRINT", "height=400,width=600");
    var printContents = document.getElementById(divName);
    generator.document.write(printContents.innerHTML.replace("Print Me"));
    generator.document.close();
    generator.focus(); // necessary for IE >= 10*/
    generator.print();
    generator.close();
  };
  handleActiveClick = (id) => {
    const { order } = this.props.ord;
    let selectUser = order.order_items.find((gym) => gym.id === id);
    let order_item_status = selectUser.order_item_status ? false : true;

    this.props.updateIrdertem({ order_item_status }, id, this.props.history);
  };
  changetab = (id) => {
    const {acceptedorders, barorders, kitchenorders } = this.props.ord
    const { selectUser } = this.props;
    this.setState({acceptid : id})
    console.log(id)
 
    if (id === 1){
    
    } else if(id === 2){
      let fff = acceptedorders.map((element) => {
        return {...element, order_items: element.order_items.filter((subElement) => subElement.item.is_bar_item === false)}
        })
        let filtered = fff.filter(function (el) {
         
          return el.order_items.length > 0 ;
        }) 
        this.setState({kitchenorder : filtered})
 
    }else if(id === 3){
      let fff = acceptedorders.map((element) => {
        return {...element, order_items: element.order_items.filter((subElement) => subElement.item.is_bar_item === true)}
        })
       let filtered = fff.filter(function (el) {
            return el.order_items.length > 0 ;
          }) 
        this.setState({barorder : filtered})
   
    }
  };

  render() {
    const { selectUser } = this.props;
    console.log(selectUser);
    /* let name =
      !isEmpty(this.props.auth) &&
      !isEmpty(this.props.auth.user) &&
      !isEmpty(this.props.auth.user.restaurant.name)
        ? this.props.auth.user.restaurant.name
        : "";
    let address =
      !isEmpty(this.props.auth) &&
      !isEmpty(this.props.auth.user) &&
      !isEmpty(this.props.auth.user.restaurant.name)
        ? this.props.auth.user.restaurant
        : "";  */
    const {
      filteredData,
      searchInput,
      isShowDeleteUser,
      code,

      order_status,
      order_status_id,
      orderstatusid,
      ageRestriction,
      orderitem
    } = this.state;
    const { order } = this.props.ord;
    const { orders } = this.props.ord;
    const { tables } = this.props.tab;
    let { orderstatuss } = this.props.ordsta;
    const { ordertable } = this.props.ord;
    const { isLoading } = this.props.errors;
    let { ordercancel } = this.props.ordsta;
    const { acceptedorders } = this.props.ord;
     let tabname = !isEmpty(this.props.selectUser) &&
     !isEmpty(this.props.selectUser.restaurant_table)
      ? this.props.selectUser.restaurant_table.name
      : "" 
          console.log("order", order);
    // Accepted & Completed
    orderstatuss = orderstatuss.filter((status_id) => status_id.id !== 1);
    orderstatuss = orderstatuss.filter((status_id) => status_id.id !== 5);
    orderstatuss = orderstatuss.filter((status_id) => status_id.id !== 6);
    orderstatuss = orderstatuss.filter((status_id) => status_id.id !== 3);

    // Cancel
    ordercancel = ordercancel.filter((status_id) => status_id.id !== 1);
    ordercancel = ordercancel.filter((status_id) => status_id.id !== 5);
    ordercancel = ordercancel.filter((status_id) => status_id.id !== 6);
    ordercancel = ordercancel.filter((status_id) => status_id.id !== 2);
    ordercancel = ordercancel.filter((status_id) => status_id.id !== 4);

    let orderprint =
      !isEmpty(order) && !isEmpty(order.order_items) ? order.order_items : [];

    let ordtab = ordertable.map((ordta) => {
      return ordta.restaurant_table_id;
    });

    let orderstatus = order.order_status_id;

    this.state.timerago = order.order_starting_time;

    let headerStyle = {
      height: 45,
      paddingTop: 15,
      fontWeight: "bold",
    };
    const columns = [
    
        {
            Header: "S.No",
            id: "row",
            maxWidth: 75,
            headerStyle: headerStyle,
            filterable: false,
            Cell: (row) => {
              return <div>{row.index + 1}</div>;
            },
          },
          {
            Header: "Name",
            accessor: "item.name",
            // maxWidth: 100,
            headerStyle: headerStyle,
          },
          {
            Header: "Add On",
    
            // maxWidth: 75,
            headerStyle: headerStyle,
            filterable: false,
            Cell: (row) => {
              return (
                <div>
                  {row.original.order_item_add_ons.map((ordd) => {
                    return !isEmpty(ordd) &&
                      !isEmpty(ordd.item_add_on) &&
                      !isEmpty(ordd.item_add_on.add_on)
                      ? ordd.item_add_on.add_on.name
                      : "";
                  })}
                </div>
              );
            },
          },
          {
            Header: "Notes",
            accessor: "instruction",
            maxWidth: 250,
            headerStyle: headerStyle,
          },
         
         
          {
            Header: "Quantity",
            accessor: "quantity",
            //   maxWidth: 100,
            headerStyle: headerStyle,
          },
        
         
          {
            Header: "Status",
    
           // maxWidth: 75,
            headerStyle: headerStyle,
            filterable: false,
            Cell: (row) => {
              return (
                <div>
                  {row.original.is_active ? (
                    <div className="badge badge-success">Available</div>
                  ) : (
                    <div className="badge badge-danger">Unavailable</div>
                  )}
                </div>
              );
            },
          },
    ];

    const fields = ["id", "name", "code"];
    const relationFields = {
      manu: ["name"],
    };
    let addon = [{ id: 0, name: "cheese" }];
    return (
      <Modal
        id="popup"
        key="popup"
        open={true}
        size={"large"}
        header={this.props.name}
        onClose={this.handleCancelPopUp}
      >
        <div>
          <div className="main-panel">
            <div>
              <section className="content">
                <div className="row">
                  <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card-body">
                      <p className="card-description">
                        <h4
                          className="card-title"
                          style={{ fontWeight: "bold" }}
                        >
                          Order Details
                        </h4>
                      </p>
                      <div className="row">
                            <div class="col-lg-12 grid-margin stretch-card">
                              <div class="card" style={{ height: "150px" }}>
                                <div class="card-body">
                                  <form className="forms-sample">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <p style={{ fontWeight: "bold" }}>
                                            Order Id:{" "}
                                            <span
                                              style={{ fontWeight: "normal" }}
                                            >
                                             {!isEmpty(this.props.selectUser)
                                                ? this.props.selectUser.dynamic_order_id
                                                : ""} 
                                            </span>
                                          </p>
                                        </div>
                                        <div className="form-group">
                                          <p style={{ fontWeight: "bold" }}>
                                            Date:{" "}
                                            <span
                                              style={{ fontWeight: "normal" }}
                                            >
                                             {!isEmpty(this.props.selectUser)
                                                ? this.props.selectUser.createdAt
                                                : ""} 
                                            </span>
                                          </p>
                                        </div>
                                        <div className="form-group">
                                          <p style={{ fontWeight: "bold" }}>
                                            Order Accepted:{" "}
                                            <span
                                              style={{ fontWeight: "normal" }}
                                            >
                                             {!isEmpty(this.props.selectUser)
                                                ?    <Moment interval={30000} fromNow>
                                                {this.props.selectUser.order_starting_time}
                                              </Moment>
                                                : ""} 
                                            </span>
                                          </p>
                                        </div>
                                       
                                        </div>
                                        <div className="col-md-6">
                                        <div className="form-group">
                                          <p style={{ fontWeight: "bold" }}>
                                            Name:{" "}
                                            <span
                                              style={{ fontWeight: "normal" }}
                                            >
                                             {!isEmpty(this.props.selectUser)
                                                ? this.props.selectUser.user.name
                                                : ""} 
                                            </span>
                                          </p>
                                        </div>
                                       
                                        <div className="form-group">
                                          <p style={{ fontWeight: "bold" }}>
                                            Table Name:{" "}
                                            <span
                                              style={{ fontWeight: "normal" }}
                                            >
                                             {tabname}
                                            </span>
                                          </p>
                                        </div>
                                        </div>
                                        </div>
                                        </form>
                                        </div>
                                        </div>
                                        </div>
                                        </div>

                      {/*   {!isLoading ? ( */}

                      <div className="row">
                      <h4
                          className="card-title"
                          style={{ fontWeight: "bold" }}
                        >
                          Items
                        </h4>
                        <div class="table-responsive">
                          <Table
                            data={
                              filteredData.length > 0
                                ? filteredData
                                : searchInput
                                ? filteredData
                                : orderitem
                            }
                            columns={columns}
                          />
                        </div>
                       
                       
                      
                        <button
                          //  id="btnPrint"
                          class="hidden-print btn btn-order btn-outline-facebook Closed"
                          // onClick={window.print}
                          onClick={this.handleCancelPopUp}
                          style={{float : "right"}}
                        >
                          Close
                        </button>
                      </div>
                     
                    </div>
                  </div>
                </div>
               
              </section>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => ({
  ord: state.ord,
  tab: state.tab,
  auth: state.auth,
  ordsta: state.ordsta,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getorder,
  updateOrder,
  getAllOrdersStatus,
  getAllOrdersItems,
  deleteItemOrderEdit,
  getAllTables,
  getAllOrdersCount,
  getAllAcceptedOrders,
  getAllOrders,
  updateIrdertem
})(OrderpopUp);
