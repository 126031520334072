import React, { Component } from "react";
import Modal from "../common/Modal/index";

export class Deletegym extends Component {
  handleCancelDeleteGym = () => {
    this.props.handleCancelDeleteGym();
  };
  handleDeleteClick = () => {
    this.props.handleDeleteClick(this.props.selectClass.id);
    this.props.handleCancelDeleteGym();
    
  };

  render() {
    const { gyms } = this.props;
    return (
      <Modal
        id="deleteres"
        key="deleteres"
        open={true}
        size={"mini-format"}
        header="Delete Item"
        onClose={this.handleCancelDeleteGym}
      >
        <div className="row">
          <div className="col-md-12">
            <label>Are you sure want to delete this Item?</label>
          </div>
          <div className="col-md-12" style={{ marginTop: "20px" }}>
            <div className="col-md-6">
              <button
                className="btn btn-danger"
                onClick={this.handleDeleteClick}
              >
                Yes
              </button>
            </div>
            <div className="col-md-6">
              <button
                className="btn btn-status custombttn"
                onClick={this.handleCancelDeleteGym}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default Deletegym;
