import {
    
    GET_ALL_TABLES,
    ADD_TABLE,
    CODE_TABLE,
    GET_TABLE
  } from "../actions/types";
  
  const initialState = {
    
    tables:[],
    tabcode: {},
    table :{}
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_TABLES:
        return {
          ...state,
         
          tables: action.payload
        }
        case ADD_TABLE:
            return {
              ...state,
             
              tables: action.payload
            }
            case CODE_TABLE:
                return {
                  ...state,
                 
                  tabcode: action.payload
                }
                case GET_TABLE:
                  return {
                    ...state,
                   
                    table: action.payload
                  }

        default:
            return state;
        }
    }