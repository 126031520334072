import {
    GET_ALL_ORDERS,
    GET_ORDER,
    GET_ORDER_TABLE_NAME,
    GET_ALL_ORDER_ITEMS,
    GET_ORDER_STATS,
    GET_ACCEPTED_ORDERS,
    GET_ALL_ORDERS_EMPTY,
    GET_ORDER_STATUS
  } from "../actions/types";
  
  const initialState = {
    
    orders:[],
    order:{},
    ordertable:[],
    orderitems: [],
    orderstats: [],
    acceptedorders : [],
    vieworderstatus : []
   
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_ORDERS:
        return {
          ...state,
         
          orders: action.payload
        };
        case GET_ORDER:
            return {
              ...state,
             
              order: action.payload
            };
            case GET_ORDER_TABLE_NAME:
              return {
                ...state,
               
                ordertable: action.payload
              };
              case GET_ALL_ORDER_ITEMS:
                return {
                  ...state,
                 
                  orderitems: action.payload
                };
                case GET_ORDER_STATS:
                return {
                  ...state,
                 
                  orderstats: action.payload
                };
                case GET_ACCEPTED_ORDERS:
                  return {
                    ...state,
                   
                    acceptedorders: action.payload
                  };
                 
                  case GET_ALL_ORDERS_EMPTY:
                    return {
                      ...state,
                     
                      acceptedorders: []
                    }; 
                    case GET_ORDER_STATUS:
                      return {
                        ...state,
                       
                        vieworderstatus: action.payload
                      };

     
      default:
        return state;
    }
  }
  