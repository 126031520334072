import axios from "axios";
//import { push } from "connected-react-router";

import { API_URI } from "../utils/config";
//import jwt_decode from "jwt-decode";

import {
  GET_ERRORS,
  GET_ALL_ORDERS,
  GET_ORDER,
  GET_ORDER_TABLE_NAME,
  GET_ALL_ORDER_ITEMS,
  GET_ORDER_STATS,
  LOADING,
  CLEAR_LOADING,
  GET_ACCEPTED_ORDERS,
  GET_ALL_ORDERS_EMPTY,
  GET_ALL_TABLES,
  GET_ORDER_STATUS
} from "./types";



// Get all Items
export const getAllOrders = (id) => async (dispatch, getState) => {
 
  dispatch(setLoading());
  let restaurant_id = await localStorage.getItem("restaurant_id");
  let tableid = ""
  if(id){
    tableid = `orders?filter={"where":{"restaurant_table_id":${id}, "restaurant_id":${restaurant_id}, "order_status_id":{"$in":[1,2]} }}`
  } else{
    tableid = `orders?filter={"where":{ "restaurant_id":${restaurant_id}, "order_status_id":{"$in":[1,2]} }}`
  }
  let  Url = `${API_URI}/${tableid}`
  let ordertable = [...getState().ord.ordertable];
    axios
      .get(Url)
      
      .then(res => {
       console.log(res)
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error
          });
          dispatch(clearLoading());
        } else {
          const { data } = res.data;
          dispatch({
            type: GET_ALL_ORDERS,
            payload: data
          });
          dispatch({
            type: GET_ORDER_TABLE_NAME,
            payload: data
          });
          /* dispatch({
            type: GET_ACCEPTED_ORDERS,
            payload: data
          }); */
          dispatch(clearLoading());
        }
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.error.message
        });
        dispatch(clearLoading());
      });
  };

   // Get single Order
export const getorder = (id) => (dispatch) => {
  dispatch(setLoading());
      axios
        .get(`${API_URI}/orders/${id}`)
        .then(async (res) => {
          console.log(res)
          if (res.data.error.code) {
            dispatch({
              type: GET_ERRORS,
              payload: res.data.error,
            });
            dispatch(clearLoading());
          } else {
            const { data } = res.data;
    
            dispatch({
              type: GET_ORDER,
              payload: data,
            });
            dispatch(clearLoading());
            
            
          }
        })
        .catch((err) => {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data.error.message,
          });
          dispatch(clearLoading());
          
        });
    };
  

    // Get all Items
export const getAllOrdersHistory = (id) => async (dispatch, getState) => {
  dispatch(setLoading());
  let restaurant_id = await localStorage.getItem("restaurant_id");

    axios
      .get(`${API_URI}/orders?filter={"where":{"restaurant_id":${restaurant_id}}}`)
      .then(res => {
        
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error
          });
        } else {
          const { data } = res.data;
          dispatch({
            type: GET_ALL_ORDERS,
            payload: data
          });
          dispatch({
            type: GET_ORDER_TABLE_NAME,
            payload: data
          });
          dispatch(clearLoading());
        }
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.error.message
        });
        dispatch(clearLoading());
      });
  };

     // Get all report
export const getAllOrdersHistoryFilter = (id) => async (dispatch, getState) => {
  dispatch(setLoading());
  let restaurant_id = await localStorage.getItem("restaurant_id");

    axios
      .get(`${API_URI}/orders?filter={"where":{"restaurant_id":${restaurant_id}, "order_status_id" : ${id}}}`)
      .then(res => {
        
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error
          });
        } else {
          const { data } = res.data;
          dispatch({
            type: GET_ALL_ORDERS,
            payload: data
          });
          dispatch({
            type: GET_ORDER_TABLE_NAME,
            payload: data
          });
          dispatch(clearLoading());
        }
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err
        });
        dispatch(clearLoading());
      });
  };
    // Update Order
export const updateOrder = (orderData, id, history) => (dispatch, getState) => {
    let orders = [...getState().ord.orders];
      axios
      .put(`${API_URI}/orders/${id}`, orderData)
      .then((res) => {
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error,
          });
        } else {
          const { data } = res.data;
          orders = orders.map((rest) => {
            if (rest.id === data.id) {
                rest = { ...rest, ...data };
            }
            return rest;
          }); 
          dispatch({
            type: GET_ORDER,
            payload: data,
          });
           dispatch({
            type: GET_ALL_ORDERS,
            payload: orders,
          }); 
          dispatch(getAllAcceptedOrders())
   //  history.push("/orders");
          
        }
      })
      .catch((err) => {
         
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.error.message,
        });
        
      });
  };

 
  export const getStatsOrders = () => dispatch => {
    axios
      .get(`${API_URI}/orders/stats`)
      .then(res => {
        
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error
          });
        } else {
          const { data } = res;
          
          dispatch({
            type: GET_ORDER_STATS,
            payload: data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.error.message
        });
      });
  };

  // Get all notification count
export const getAllOrdersCount = (id) => async (dispatch, getState) => {
  let restaurant_id = await localStorage.getItem("restaurant_id");
  let ordertable = [...getState().ord.ordertable];
    axios
      .get(`${API_URI}/orders?filter={"where":{ "restaurant_id":${restaurant_id}, "order_status_id":"2" }}`)
      
      .then(res => {
       
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error
          });
        } else {
          const { data } = res.data;
          dispatch({
            type: GET_ALL_ORDERS,
            payload: data
          });
          dispatch({
            type: GET_ORDER_TABLE_NAME,
            payload: data
          });
        }
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.error.message
        });
      });
  };

  export const setLoading = () => {
    return {
      type: LOADING,
    };
  };
  
  export const clearLoading = () => {
    return {
      type: CLEAR_LOADING,
    };
  };

  
  // Get all Items
export const getAllAcceptedOrders = (id) => async (dispatch, getState) => {
 
  dispatch(setLoading());
  let restaurant_id = await localStorage.getItem("restaurant_id");
  let Url = `${API_URI}/orders?filter={"where":{ "restaurant_id":${restaurant_id}, "order_status_id":{"$in":[2]} }}`
  
  let ordertable = [...getState().ord.ordertable];
    axios
      .get(Url)
      
      .then(res => {
        
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error
          });
          dispatch(clearLoading());
        } else {
          const { data } = res.data;
          dispatch({
            type: GET_ACCEPTED_ORDERS,
            payload: data
          });
         
          dispatch(clearLoading());
        }
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.error.message
        });
        dispatch(clearLoading());
      });
  };

  export const clearData = () => (dispatch) => {
    dispatch({
      type: GET_ALL_ORDERS_EMPTY,
    });
  };

   // Update Staff in all orders tiles
 export const updateTable = (tabData,id) => (dispatch, getState) => {

  let tables = [...getState().tab.tables];

  axios
    .put(`${API_URI}/restaurant_tables/${id}`,tabData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        tables = tables.map((rest) => {
          if (rest.id === data.id) {
            rest = { ...rest, ...data };
          }
          return rest;
        }); 

         dispatch({
          type: GET_ALL_TABLES,
          payload: tables,
        }); 
     
        
      }
    })
    .catch((err) => {
       
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      });
    });
};

  // Get all View order status
  export const getAllOrdersStatus = (id) => async (dispatch, getState) => {
 
    dispatch(setLoading());
    let restaurant_id = await localStorage.getItem("restaurant_id");
    let Url = `${API_URI}/orders?filter={"where":{ "restaurant_id":${restaurant_id}, "order_status_id":{"$in":[4]}}}`
    
    
      axios
        .get(Url)
        
        .then(res => {
          console.log(res)
          if (res.data.error.code) {
            dispatch({
              type: GET_ERRORS,
              payload: res.data.error
            });
            dispatch(clearLoading());
          } else {
            const { data } = res.data;
            dispatch({
              type: GET_ORDER_STATUS,
              payload: data
            });
           
            dispatch(clearLoading());
          }
        })
        .catch(err => {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data.error.message
          });
          dispatch(clearLoading());
        });
    };