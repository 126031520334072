
// Development Server
export const API_URI = "https://payclean.tecnovaters.com/api/v1";
export const IMAGE_URI = "https://payclean.tecnovaters.com/";

// production server 
//export const API_URI = "https://api.pay-clean.com/api/v1";
//export const IMAGE_URI = "https://api.pay-clean.com";




