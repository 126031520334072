import React, { Component } from "react";
import Modal from "../common/Modal/index";

export class Agerestriction extends Component {
    constructor(props) {
        super(props)
       
    }
   handleCancelAgerestriction = () => {
    this.props.handleCancelAgerestriction();
  };


  render() {
    
    return (
      <Modal
        id="deleteres"
        key="deleteres"
        open={true}
        size={"mini-format"}
        header="Warning"
        onClose={this.handleCancelAgerestriction}
      >
        <div className="row">
          <div className="col-md-12">
            <label>Warning: An Alcoholic Beverage was ordered at {this.props.tabname} <br/>Please verify age at table before accepting orders.</label>
          </div>
         
        </div>
      </Modal>
    );
  }
}

export default Agerestriction;
