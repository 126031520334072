import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

class Topmenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
    
        <div class="horizontal-menu" style={{ width: "100%" }}>
          <nav class="bottom-navbar">
            <div class="container">
              <ul class="nav page-navigation">
                <li  className={`${
                  this.props.active === "orders" ? "nav-item active" : ""
                }`}>
                  <NavLink class="nav-link " to="/orders" style={{color : "black"}}>
                    <i class="mdi mdi-file-document-box menu-icon"></i>
                    <span class="menu-title">Home</span>
                  </NavLink>
                </li>

                <li  className={`${
                  this.props.active === "items" ? "nav-item active" : ""
                } `}>
                  <NavLink to="/staffmenu" class="nav-link" style={{color : "black"}}>
                    <i class="mdi mdi-silverware-variant menu-icon"></i>
                    <span class="menu-title">Menu</span>
                    <i class="menu-arrow"></i>
                  </NavLink>
                </li>
                
                <li  className={`${
                  this.props.active === "orderhistory" ? "nav-item active" : ""
                } `}>
                  <NavLink to="/orderhistory" class="nav-link"style={{color : "black"}}>
                    <i class="mdi mdi-note-text menu-icon"></i>
                    <span class="menu-title">Orders</span>
                    <i class="menu-arrow"></i>
                  </NavLink>
                </li>
                <li  className={`${
                  this.props.active === "acceptedorder" ? "nav-item active" : ""
                } `}>
                  <NavLink to="/acceptedorder" class="nav-link"style={{color : "black"}}>
                    <i class="mdi mdi-receipt menu-icon"></i>
                    <span class="menu-title">Accepted Orders</span>
                    <i class="menu-arrow"></i>
                  </NavLink>
                </li>
              </ul>
            </div>
          </nav>
        </div>
   
    );
  }
}

export default Topmenu