import React, { Component, Suspense, lazy  } from 'react';


import { HashRouter as Router, Route, Switch } from "react-router-dom";
/*  import {Router,Route, browserHistory} from 'react-router';
import {  Switch } from "react-router-dom";  */



import { Provider } from "react-redux";
import store from "./store";
import setAuthToken from "./utils/setAuthToken";
import {
  clearCurrentProfile,
  logoutUser,
  currentUser
} from "./actions/authAction";
import PrivateRoute from "./components/common/PrivateRoute";
import "react-table/react-table.css";
import logo from './logo.svg';
//import './App.css';
import './App.scss';
import NotFound from "./components/not-found/notFound"
import firebase from 'firebase';

import SpinnerRestaurant from "../src/components/common/restaurantSpinner/index"

import Acceptedorder from "./components/Orders/acceptedOrder"
import { toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import "./assests/css/vertical-layout-light/style.css"
import "./assests/css/style.css"
import "./assests/vendors/mdi/css/materialdesignicons.min.css"
import "./assests/vendors/base/vendor.bundle.base.css"
import "./assests/images/favicon.png"
import  "./assests/css/maps/style.css.map"
const Dashboard = lazy(() => import("./components/dashboard"));
const Login = lazy(() => import("./components/auth/login"));
const OrderReport = lazy(() => import("./components/Reports/orderReport"));
const Users = lazy(() => import("./components/users/index"));
const Viewuser = lazy(() => import("./components/users/viewUser"));
const Restaurant = lazy(() => import("./components/restaurants/index"));
const Addrestaurant = lazy(() => import("./components/restaurants/addRestaurant"));
const Viewrestaurant = lazy(() => import("./components/restaurants/viewRestaurant"));
const Editrestaurant = lazy(() => import("./components/restaurants/editRestaurant"));
const Restaurantverification = lazy(() => import("./components/newRestaurantVerification/index"));
const Item = lazy(() => import("./components/Item"));
const Additem = lazy(() => import("./components/Item/addItem"));
const Edititem = lazy(() => import("./components/Item/edititem"));
const Menu = lazy(() => import("./components/Menu/index"));
const Addmenu = lazy(() => import("./components/Menu/addMenu"));
const Editmenu = lazy(() => import("./components/Menu/editMenu"));
const Cuisines = lazy(() => import("./components/Cuisines"));
const Addcuisines = lazy(() => import("./components/Cuisines/addCuisines"));
const Editcuisines = lazy(() => import("./components/Cuisines/editCuisines"));
const Qrcode = lazy(() => import("./components/Table/qrCode"));
const Table = lazy(() => import("./components/Table/index"));
const Orders = lazy(() => import("./components/Orders/index"));
const AddTable = lazy(() => import("./components/Table/addTable"));
const Edittable = lazy(() => import("./components/Table/edittable"));
const Print = lazy(() => import("./components/common/Print/index"));
const Addon = lazy(() => import("./components/AddOn/index"));
const AddonAdd = lazy(() => import("./components/AddOn/addAddOn"));
const EditAddon = lazy(() => import("./components/AddOn/editAddon"));
const Orderhistory = lazy(() => import("./components/Orders/orderHistory"));
const Editorder = lazy(() => import("./components/Orders/editOrder"));
const Restaurantedit = lazy(() => import("./components/headerMenu/editRestaurant"));
const Check = lazy(() => import("./check"));
const onBoardingForm = lazy(() => import("./components/onBoardingForm"));
const forgotpasswordotpverification = lazy(() => import("./components/forgotPassword/forgotPasswordOTP"));
const Resetpassword = lazy(() => import("./components/forgotPassword/resetPassword"));
const Staff = lazy(() => import("./components/Staff/index"));
const AddStaff = lazy(() => import("./components/Staff/addStaff"));
const EditStaff = lazy(() => import("./components/Staff/editStaff"));
const ViewStaff = lazy(() => import("./components/Staff/viewStaff"));
const Menuoption = lazy(() => import("./components/Item/menuOption"));
const CustomMenu = lazy(() => import("./components/Item/CustomMenu"));
const Choosemenu = lazy(() => import("./components/Item/ChooseMenu"));
const Variations = lazy(() => import("./components/Item/variations"));
const Menuaddon = lazy(() => import("./components/Item/addOn"));
const SignUp = lazy(() => import("./components/SignUpForm"));
const Restaurantrequest = lazy(() => import("./components/restaurants/restaurantRequest"));
const Changepassword = lazy(() => import("./components/headerMenu/Changepassword"));
const Restaurantrequestedview = lazy(() => import("./components/restaurants/restaurantRequestedView"));
const TaxReport = lazy(() => import("./components/Reports/taxReport")); 
const RestaurantTaxReport = lazy(() => import("./components/Reports/restaurantTaxReport"));
const Staffreport = lazy(() => import("./components/Reports/StaffReport"));
const Ratingreport = lazy(() => import("./components/Reports/ratingReport"));
const Staffitems = lazy(() => import("./components/Item/staffItems"));
const Orderstatus = lazy(() => import("./components/Orders/viewOrderstatus"));
// Check for token
if (localStorage.payClean) {
  // Set auth token header auth
  setAuthToken(localStorage.payClean);
  store.dispatch(currentUser());
  /*  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.payclean);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  
 */

  if (!localStorage.payClean) {
    // Logout user
    store.dispatch(logoutUser());
    // Clear current Profile
    store.dispatch(clearCurrentProfile());
    // Redirect to login
    window.location.href = `${window.location.origin.toString()}/`;
    // window.location.href = "http://aqar.tecnovaters.com/";
  }
}


toast.configure()
class App extends Component{
  componentDidMount() {
    firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            console.log('User is signed in.');
        } else {
            // No user is signed in.
            console.log('No user is signed in.');
        }
    });
  } 
  render(){
    
  return (
    <Provider store={store}>
    {/*   <MyErrorBoundary> */}
        <Suspense fallback={<div><SpinnerRestaurant/></div>}>
    <Router    basename={process.env.REACT_APP_ROUTER_BASE || ''}>
    
          <div>
            <Route exact path="/" component={Login} />
            <Route exact path="/login" component={Login} />
            
          {!localStorage.restaurant_id ? 
          <>
            <Switch>
           
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
            </Switch> 
            <Switch>
              <PrivateRoute exact path="/users" component={Users} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/restaurants" component={Restaurant} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/addrestaurant" component={Addrestaurant} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/viewrestaurant/:id" component={Viewrestaurant} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/editrestaurant/:id" component={Editrestaurant} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/viewuser/:id" component={Viewuser} />
            </Switch>
            </> : ""}
            <Switch>
              <Route exact path="/restaurantverification/:token" component={Restaurantverification} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/items" component={Item} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/additem" component={Additem} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/edititem/:id" component={Edititem} />
             
            </Switch>
            <Switch>
              <PrivateRoute exact path="/menu" component={Menu} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/addmenu" component={Addmenu} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/editmenu/:id" component={Editmenu} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/cuisines" component={Cuisines} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/addcuisines" component={Addcuisines} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/editcuisine/:id" component={Editcuisines} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/table" component={Table} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/addtable" component={AddTable} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/qrcodegenerated" component={Qrcode} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/orders" component={Orders} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/print" component={Print} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/check" component={Check} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/addon" component={Addon} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/addonAdd" component={AddonAdd} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/editaddon/:id" component={EditAddon} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/orderhistory" component={Orderhistory} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/acceptedorder" component={Acceptedorder} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/editorder/:id/:name" component={Editorder} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/restaurantedit/:id" component={Restaurantedit} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/edittable/:id" component={Edittable} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/orderReport" component={OrderReport} />
            </Switch>
          <Switch>
              <PrivateRoute exact path="/taxreport" component={TaxReport} />
            </Switch> 
            <Switch>
              <PrivateRoute exact path="/restauranttaxreport" component={RestaurantTaxReport} />
            </Switch> 
            <Switch>
              <PrivateRoute exact path="/staffreport" component={Staffreport} />
            </Switch> 
            <Switch>
              <PrivateRoute exact path="/ratingreport" component={Ratingreport} />
            </Switch> 
            <Switch>
              <PrivateRoute exact path="/onboardingform" component={onBoardingForm} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/staff" component={Staff} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/addstaff" component={AddStaff} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/editstaff/:id" component={EditStaff} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/viewstaff/:id" component={ViewStaff} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/menuoption" component={Menuoption} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/custommenu" component={CustomMenu} />
              </Switch>
            <Switch>
              <PrivateRoute exact path="/choosemenu" component={Choosemenu} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/variations" component={Variations} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/menuaddon" component={Menuaddon} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/restaurantrequest" component={Restaurantrequest} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/changepassword" component={Changepassword} />
            </Switch> 
            <Switch>
              <PrivateRoute exact path="/restaurantrequestedview/:id" component={Restaurantrequestedview} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/vieworderstatus" component={Orderstatus} />
            </Switch>
            <Switch>
              <Route exact path="/signupform" component={SignUp} />
            </Switch>
            <Switch>
              <Route exact path="/staffmenu" component={Staffitems} />
            </Switch>
            <Route
              exact
              path="/forgotpasswordotpverification"
              component={forgotpasswordotpverification}
            ></Route>
             <Route
              exact
              path="/resetpassword"
              component={Resetpassword}
            ></Route>
            <Route exact path="/not-found" component={NotFound} />
          </div>
        </Router>
        </Suspense>
      {/*   </MyErrorBoundary> */}
      </Provider>
       
     
        
  );
}
}




export default App;