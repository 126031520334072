import React, { Component, Fragment } from "react";
import ReactTable from 'react-table';

export class Table extends Component {
  getTrProps = (state, rowInfo, instance) => {
    if (rowInfo) {
      return {
        style: {
          background: rowInfo.index % 2 === 0 ? "#f9f9f9" : "#ffffff",
          textAlign: "center",
          
        }
      };
    }
    return {};
  };
  render() {
    let { data, columns } = this.props;

    return (
      <Fragment>
        <ReactTable
          data={data}
          columns={columns}
          minRows={0}
          //filterable={true}
          getTrProps={this.getTrProps}
          className="-striped -highlight"
        />
       
      </Fragment>
    );
  }
}

export default Table;
