

import firebase from "firebase";

import 'firebase/messaging';

const Config = {
    apiKey: "AIzaSyCLAX9MmCUbZcAIZP68Q-9KfBh9dFuVnzw",
    authDomain: "paycleanadminserver.firebaseapp.com",
    databaseURL: "https://paycleanadminserver.firebaseio.com",
    projectId: "paycleanadminserver",
    storageBucket: "paycleanadminserver.appspot.com",
    messagingSenderId: "398981371238",
    appId: "1:398981371238:web:bffb89099cc2b8ea9ca4a7"
}
firebase.initializeApp(Config);

const messaging = firebase.messaging();

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    messaging
      .requestPermission()
      .then(() => messaging.getToken())
      .then((firebaseToken) => {
        resolve(firebaseToken);
      })
      .catch((err) => {
        reject(err);
      });
  });



 


export default firebase

 