import React from "react"   
import { connect } from "react-redux";
const Box = (props) => {

return (

            <div class="col-lg-3 grid-margin stretch-card">
              <div class="card" >
                <div class="card-body pb-0" style={{height: "120px"}}>
                  <div class="d-flex align-items-center justify-content-between">
<h2 class="text-success font-weight-bold" style={{marginTop:"-24px"}}>{props.count}</h2>
					<div className="icon">
					<i className={` ${props.icon}`}></i>
					</div>
                  </div>
                </div>
              
<div class="line-chart-row-title">{props.name}</div>
              </div>
           
			{/* <div class="col-lg-3 grid-margin stretch-card">
              <div class="card" >
                <div class="card-body pb-0" style={{height: "120px"}}>
                  <div class="d-flex align-items-center justify-content-between">
                    <h2 class="text-success font-weight-bold">0</h2>
					<div className="icon">
					<i class="ion-android-restaurant"></i>
					</div>
                  </div>
                </div>
              
                <div class="line-chart-row-title">RESTAURANT</div>
              </div>
            </div>
			
			  <div class="col-lg-3 grid-margin stretch-card">
              <div class="card" >
                <div class="card-body pb-0" style={{height: "120px"}}>
                  <div class="d-flex align-items-center justify-content-between">
                    <h2 class="text-success font-weight-bold">0</h2>
					<div className="icon">
          <i class="ion-android-person"></i> 
					</div>
                  </div>
                </div>
              
                <div class="line-chart-row-title">CUSTOMERS</div>
              </div>
            </div>
            <div class="col-lg-3 grid-margin stretch-card">
              <div class="card" >
                <div class="card-body pb-0" style={{height: "120px"}}>
                  <div class="d-flex align-items-center justify-content-between">
                    <h2 class="text-success font-weight-bold">0</h2>
					<div className="icon">
          <i class="ion-android-checkbox-blank"></i>
					</div>
                  </div>
                </div>
              
                <div class="line-chart-row-title">REWARDS</div>
              </div>
            </div> */}
           
           </div>
)
}

const mapStateToProps = state => ({
	auth: state.auth
	
  });
  
  export default connect(mapStateToProps, {})(Box);
