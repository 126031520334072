import {
    ADD_BANK_DETAILS
    
  } from "../actions/types";
  
  const initialState = {
   bank: []
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      
  
      case ADD_BANK_DETAILS:
        return {
          ...state,
         bank : action.payload
        };
       
      
          
      default:
        return state;
    }
  }
  