import {
    GET_ALL_ORDER_STATUS,
    GET_CANCEL_ORDER
  } from "../actions/types";
  
  const initialState = {
    
    orderstatuss:[],
    ordercancel : []
    
    
   
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_ORDER_STATUS:
        return {
          ...state,
         
          orderstatuss: action.payload
        };
        case GET_CANCEL_ORDER:
        return {
          ...state,
         
          ordercancel: action.payload
        };
       

     
      default:
        return state;
    }
  }
  